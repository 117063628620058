import React, { useEffect } from "react";
import { Button } from "@material-tailwind/react";
import { pdfCompanyFeeGeneratorApi } from "api/pdf";

import API_URL from "config";
import { pdfOneCompanyFeeGeneratorApi } from "api/pdf";

function ReportDetails({typeOf, reportDetailsData}) {
  useEffect(() => {
    console.log("reportDetailsData DG", reportDetailsData)
  }, [reportDetailsData])

  const handleDownloadReport = () => {
    try {

    } catch (err) {
      
    }
  };

  const handleDownloadBon = (file) => {
    try {
      window.open(`${API_URL}/${file}`, '_blank')
    } catch (err) {
      console.log(err)
    }
  };

  const handleDownloadPdf = async (data) => {
    const response = await pdfOneCompanyFeeGeneratorApi(data._id);
    window.open(`${response}`, "_blank");
    try {
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <div>
      <table className="w-full mt-5">
        <thead>
          <tr>
            <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
              <p className="text-xs tracking-wide text-gray-600">Date</p>
            </th>
            <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
              <p className="text-xs tracking-wide text-gray-600">Détails</p>
            </th>
            <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
              <p className="text-xs tracking-wide text-gray-600">Responsable</p>
            </th>
            <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
              <p className="text-xs tracking-wide text-gray-600">Véhicule</p>
            </th>
            <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
              <p className="text-xs tracking-wide text-gray-600">Etat</p>
            </th>
            <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
              <p className="text-xs tracking-wide text-gray-600">Description</p>
            </th>
            <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
              <p className="text-xs tracking-wide text-gray-600">Montant</p>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td className="pt-[14px] pb-[18px] sm:text-[14px]">
              <p className="text-sm font-bold text-navy-700">
                {reportDetailsData.formatedDate}
              </p>
            </td>
            <td className="pt-[14px] pb-[18px] sm:text-[14px]">
              <p className="text-sm font-bold text-navy-700">{reportDetailsData.details}</p>
            </td>
            <td className="pt-[14px] pb-[18px] sm:text-[14px]">
              <p className="text-sm font-bold text-navy-700">{reportDetailsData.responsable.Name}</p>
            </td>
            <td className="pt-[14px] pb-[18px] sm:text-[14px]">
              <p className="text-sm font-bold text-navy-700">{reportDetailsData.responsable.UserVehicul}</p>
            </td>
            <td className="pt-[14px] pb-[18px] sm:text-[14px]">
              <p className="text-sm font-bold text-navy-700">{reportDetailsData.isAproved ? "Approuvée" : "Non Approuvée"}</p>
            </td>
            <td className="pt-[14px] pb-[18px] sm:text-[14px]">
              <p className="text-sm font-bold text-navy-700">{reportDetailsData.description}</p>
            </td>
            <td className="pt-[14px] pb-[18px] sm:text-[14px]">
              <p className="text-sm font-bold text-navy-700">{parseFloat(reportDetailsData.montant).toFixed(2)}</p>
            </td>
          </tr>
        </tbody>

        <div className="">
          <Button
            variant="gradient"
            color="blue"
            onClick={() => handleDownloadBon(reportDetailsData.bonFile)}
            className=""
          >
            <span>Telecharger BON</span>
          </Button>

          <Button
            variant="gradient"
            color="blue"
            onClick={() => handleDownloadPdf(reportDetailsData)}
            className="mt-2"
          >
            <span>Telecharger PDF</span>
          </Button>
        </div>
      </table>
    </div>
  );
}

export default ReportDetails;
