import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  Spinner,
  IconButton,
} from "@material-tailwind/react";

import {
  IoMdArrowDropleftCircle,
  IoMdArrowDroprightCircle,
} from "react-icons/io";

import ReportDetails from "../ReportDetails";
import { getAllReportsListApi } from "api/gestionStock";

function BR({ selected, filterInputs, accessList }) {
  const [openReportDetails, setOpenReportDetails] = useState(false);

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [reportsList, setReportsList] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  const [active, setActive] = useState(1);
  const [currentPagination, setCurrentPagination] = useState(1);

  const [numberOfPages, setNumberOfPages] = useState(0);
  const [numberOfPaginations, setNumberOfPaginations] = useState(0);
  const [numberOfReports, setNumberOfReports] = useState(0);

  const [selectedReportId, setSelectedReportId] = useState({})

  useEffect(() => {
    getInitialData(
      1,
      currentYear,
      "Avoir",
      filterInputs.clientName,
      filterInputs.etatMagasin,
      "V",
      "",
      "",
      ""
    );
    setActive(1);
    setCurrentPagination(1)
  }, [selected, filterInputs]);

  const getInitialData = async (
    page,
    currentYear,
    MP,
    clientName,
    etatMagasin,
    EtatBonLivraison,
    EtatFacturation,
    EtatMagasinierTwo,
    EtatValidationB
  ) => {
    try {
      setIsLoading(true);
      const reportsData = await getAllReportsListApi(
        page,
        currentYear,
        MP,
        clientName,
        etatMagasin,
        EtatBonLivraison,
        EtatFacturation,
        EtatMagasinierTwo,
        EtatValidationB
      );
      setReportsList(reportsData);

      setNumberOfPages(reportsData.pagination.numberOfPages);
      setNumberOfPaginations(reportsData.pagination.numberOfPaginations);
      setNumberOfReports(reportsData.pagination.numberOfClients);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenReportDetails = (id) => {
    if(accessList.Read) {
      setSelectedReportId(id)
      setOpenReportDetails(!openReportDetails);
    }
  };

  const getItemProps = (index) => ({
    variant: active === index ? "filled" : "text",
    color: "gray",
    onClick: () => {
      getInitialData(index, currentYear, "Avoir", filterInputs.clientName, "", "", "", "", "");
      setActive(index);
    },
  });

  const next = () => {
    if (currentPagination === numberOfPaginations) return;
    setCurrentPagination(currentPagination + 1);
  };

  const prev = () => {
    if (currentPagination === 1) return;
    setCurrentPagination(currentPagination - 1);
  };

  return (
    <>
      <div>
        <div style={{ height: "40vh", overflowY: "scroll" }}>
          <Dialog
            size="xl"
            open={openReportDetails}
            handler={handleOpenReportDetails}
          >
            <DialogHeader>
              <p className="w-full text-center">Avoir</p>
            </DialogHeader>
            <DialogBody>
              <ReportDetails typeOf="BR" selectedReportId={selectedReportId} />
            </DialogBody>
          </Dialog>

          {isLoading ? (
            <div className="flex w-full items-center justify-center">
              <Spinner className="h-12 w-12" />
            </div>
          ) : (
            reportsList?.data.map((el) => (
              <Card className="mt-6 w-full cursor-pointer dark:bg-indigo-900">
                <CardBody onClick={() => handleOpenReportDetails(el._id)}>
                  <Typography
                    variant="h5"
                    color="blue-gray"
                    className="mb-2 dark:text-white"
                  >
                    {el.InfoGenerale.NomSociete} - {el.RBL}
                  </Typography>
                  <Typography>
                    {el.ModeOperation} / {el.CommercialName}
                  </Typography>
                  <Typography>{el.InfoGenerale.Adress}</Typography>
                  <Typography>
                    {el.CurrentDate} - {el.CurrentTime}
                  </Typography>
                </CardBody>
              </Card>
            ))
          )}
        </div>

        {!isLoading && numberOfReports != 0 && (
          <div className="mt-4 flex items-center gap-4">
            <Button
              variant="text"
              className="flex items-center gap-2"
              onClick={prev}
              disabled={currentPagination === 1}
            >
              <IoMdArrowDropleftCircle size={30} />
            </Button>
            <div className="flex items-center gap-2">
              {currentPagination !== numberOfPaginations
                ? // paginations row
                  Array.from({
                    length: numberOfPages > 5 ? 5 : numberOfPages,
                  }).map((el, index) => {
                    const pageNumber = index + 1 + (currentPagination - 1) * 5;
                    return (
                      <IconButton {...getItemProps(pageNumber)}>
                        {pageNumber}
                      </IconButton>
                    );
                  })
                : // last pagination row
                  Array.from({ length: numberOfPages % 5 }).map((el, index) => {
                    const pageNumber = index + 1 + (currentPagination - 1) * 5;
                    return (
                      <IconButton {...getItemProps(pageNumber)}>
                        {pageNumber}
                      </IconButton>
                    );
                  })}
            </div>
            <Button
              variant="text"
              className="flex items-center gap-2"
              onClick={next}
              disabled={
                currentPagination === numberOfPaginations ||
                numberOfPaginations === 0
              }
            >
              <IoMdArrowDroprightCircle size={30} />
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default BR;
