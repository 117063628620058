import React, { useEffect, useState } from "react";
import { Button } from "@material-tailwind/react";
import { getOneReportDetailsApi } from "api/gestionStock";
import { pdfInvoceGeneratorApi, pdfAvoirGeneratorApi } from "api/pdf";

function ReportDetails({typeOf, selectedReportId}) {
  const [reportDetails, setReportDetails] = useState({});
  const [reportCategories, setReportCategories] = useState([]);
  const [reportPrices, setReportPrices] = useState({
    HT: 0,
    TTC: 0
  })

  useEffect(() => {
    getReportDetails(selectedReportId);
  }, [selectedReportId]);

  const getReportDetails = async (reportID) => {
    try {
      console.log("reportID", reportID);

      const reportDetails = await getOneReportDetailsApi(reportID);
      setReportDetails(reportDetails);

      const reportCategories = Object.getOwnPropertyNames(
        reportDetails.Rapport
      );
      setReportCategories(reportCategories);

      let totalHT = 0;
      let totalTTC = 0;

      reportCategories.map(el => {
        const { TotaleHT, TTC } = reportDetails.Rapport[el];
        totalHT += TotaleHT;
        totalTTC += TTC;

        setReportPrices((prevPrices) => ({
          ...prevPrices,
          HT: totalHT,
          TTC: totalTTC
        }));
      })

    } catch (err) {
      console.log(err);
    }
  };

  const handleDownloadReport = async (category, index) => {
    try {
      if(reportDetails.ModeOperation === "Avoir") {
        const response = await pdfAvoirGeneratorApi(selectedReportId, category, index)
        window.open(`${response}`, '_blank')
      }
      else {
        const response = await pdfInvoceGeneratorApi(selectedReportId, category, index)
        window.open(`${response}`, '_blank')
      }
    } catch (err) {}
  };

  return (
    <div>
      <table className="w-full mt-5">
        <thead>
          <tr>
            <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
              <p className="text-xs tracking-wide text-gray-600">Nom</p>
            </th>
            <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
              <p className="text-xs tracking-wide text-gray-600">Quantité</p>
            </th>
            <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
              <p className="text-xs tracking-wide text-gray-600">Réduction Commerciale</p>
            </th>
            <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
              <p className="text-xs tracking-wide text-gray-600">Montant HT</p>
            </th>
            <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
              <p className="text-xs tracking-wide text-gray-600">Montant TTC</p>
            </th>
          </tr>
        </thead>

        {reportDetails?.Rapport && (
            <tbody>
              {Object.getOwnPropertyNames(reportDetails.Rapport).map((el) =>
                reportDetails.Rapport[el].produits.map((el2) => (
                  <tr>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-center text-sm font-bold text-navy-700">
                        {el2.name}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-center text-sm font-bold text-navy-700">
                        {el2.quantiteByUnity}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-center text-sm font-bold text-navy-700">
                        {el2.escompte === "" ? "" : `${el2.escompte}%`}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-center text-sm font-bold text-navy-700">
                        {parseFloat(el2.totalPrice).toFixed(2)}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-center text-sm font-bold text-navy-700">
                        {parseFloat(el2.totalPriceTTC).toFixed(2)}
                      </p>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          )}

          {reportCategories.map((el, index) => (
            <div className="mt-2">
              <Button
                variant="gradient"
                color="blue"
                onClick={() => handleDownloadReport(el, index)}
                className=""
              >
                <span>Telecharger PDF : {el}</span>
              </Button>
            </div>
          ))}
      </table>

      <table className="w-full mt-5">
        <thead>
          <tr>
            <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
              <p className="text-xs tracking-wide text-gray-600">Montant total HT</p>
            </th>
            <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
              <p className="text-xs tracking-wide text-gray-600">Montant total TTC</p>
            </th>
          </tr>
        </thead>

        {
          reportDetails?.Rapport &&
          <tbody>
            <tr>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <p className="text-center text-sm font-bold text-navy-700">
                  {parseFloat(reportPrices.HT).toFixed(2)}
                </p>
              </td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <p className="text-center text-sm font-bold text-navy-700">
                  {parseFloat(reportPrices.TTC).toFixed(2)}
                </p>
              </td>
            </tr>
          </tbody>
        }

      </table>
    </div>
  );
}

export default ReportDetails;
