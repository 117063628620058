import React, { useEffect, useState } from "react";
import Card from "components/card";

import FeesCard from "./FeesCard";

import {
  Tabs,
  TabsHeader,
  TabsBody,
  // Tab,
  TabPanel,
  Spinner
} from "@material-tailwind/react";
import { getAllTcpsApi } from "api/filtersData";
import { getAllDgCompanyFeesApi, getAllTcpCompanyFeesApi } from "api/companyFees";
import { getAllOperatorsApi } from "api/filtersData";

function FeesListDG({ accessList, isAdmin }) {
  const [isLoading, setIsLoading] = useState(false);

  const [listOfTcp, setListOfTcp] = useState([]);
  const [tableData, setTableData] = useState([])

  const [TabsData, setTabsData] = useState([])

  const [filtredObject, setFiltredObject] = useState({
    typeOfOp: "",
    responsable: "",
    cvValidationStaus: "",
    fdValidationStatus: "",
    tlValidationStatus: "",
    dgValidationStatus: "",
    selectedFromDate: `${new Date().getFullYear()}-01-01`,
    selectedToDate: `${new Date().getFullYear()}-12-31`
  });

  useEffect(() => {
    getFilterData()
    getInitialData(filtredObject)
  }, [accessList, isAdmin]);

  const getFilterData = async () => {
    try {
      const response = await getAllOperatorsApi()
      setListOfTcp(response);
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowToast = async () => {
    getInitialData(filtredObject)
  }

  const handleShowTcpToast = async () => {
    getInitialData(filtredObject)
  }

  const getInitialData = async (filtredObjectData) => {
    try {
      if(filtredObjectData.typeOfOp === "") {
        filtredObjectData.typeOfOp = "CV"
      }

      setIsLoading(true)

      const response = await getAllDgCompanyFeesApi(filtredObjectData)
      setTableData(response)

      setTabsData([
        {
          label: "Maroc",
          value: "Morocco",
          desc: <FeesCard tableData={response} accessList={accessList} isAdmin={true} showToast={handleShowToast} />,
        }
      ])
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setIsLoading(false)
    }
  }

  const handleFiltersInputChange = (e) => {
    const { name, value } = e.target;
    setFiltredObject((prevInputFilterValue) => ({
      ...prevInputFilterValue,
      [name]: value,
    }));

    if (name === "typeOfOp") filtredObject.typeOfOp = value;

    if (name === "responsable") filtredObject.responsable = value;

    if (name === "cvValidationStaus") filtredObject.cvValidationStaus = value;

    if (name === "fdValidationStatus") filtredObject.fdValidationStatus = value;

    if (name === "tlValidationStatus") filtredObject.tlValidationStatus = value;

    if (name === "dgValidationStatus") filtredObject.dgValidationStatus = value;

    getInitialData(filtredObject)
  };

  return (
    <div>
      {
        isLoading ?
        <div className="flex w-full items-center justify-center">
          <Spinner className="h-12 w-12" />
        </div> :
      <Card extra={"w-full pb-10 p-4 h-full"}>
      <header className="relative flex items-center justify-between">
        <div></div>
      </header>

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Filtrer par statut
          </small>
          <div>
            <select
              label="select Version"
              className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

              name="dgValidationStatus"
              value={filtredObject.dgValidationStatus}
              onChange={handleFiltersInputChange}
            >
              <option value="">Tout</option>
              <option value="V">Validée</option>
              <option value="A">Annulée</option>
              <option value="N">En cours</option>
            </select>
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Filtrer par TCP
          </small>
          <div>
            <select
              label="select Version"
              className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              
              name="responsable"
              value={filtredObject.responsable}
              onChange={handleFiltersInputChange}
            >
              <option value="">Tout</option>
              {listOfTcp.map((el) => (
                <option value={el._id}>{el.Name}</option>
              ))}
            </select>
          </div>
        </div>

        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Filtrer par type d'opération
          </small>
          <div>
            <select
              label="select Version"
              className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

              name="typeOfOp"
              value={filtredObject.typeOfOp}
              onChange={handleFiltersInputChange}
            >
              {
                accessList.VehicleCharges && <option value="CV">Charges véhicules</option>
              }
              {
                accessList.TravellingExpenses && <option value="FD">Frais de déplacement</option>
              }
              {
                accessList.LocalTransportationCosts && <option value="TL">Frais de transport local</option>
              }
            </select>
          </div>
        </div>
      </div>

      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden" style={{ height: "60vh", overflowY: "scroll" }}>
        <Tabs value="Morocco">
          <TabsHeader className="flex flex-col md:flex-row"></TabsHeader>
          <TabsBody>
            {TabsData.map(({ value, desc }) => (
              <TabPanel key={value} value={value}>
                {desc}
              </TabPanel>
            ))}
          </TabsBody>
        </Tabs>
      </div>
    </Card>
      }
    </div>
  );
}

export default FeesListDG;

// FeesListDG
