import API_URL from '../config';
import axios from 'axios';

import { getCookie } from './../utils/cookies';

export const getAllReportsListApi = async(page, currentYear, MP, clientName, etatMagasin, EtatBonLivraison, EtatFacturation, EtatMagasinierTwo, EtatValidationB) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/rapport/getAllRapportsList/${id}/${iv}?limit=20&page=${page}&currentYear=${currentYear}&MP=${MP}&clientName=${clientName}&etatMagasin=${etatMagasin}&EtatBonLivraison=${EtatBonLivraison}&EtatFacturation=${EtatFacturation}&EtatMagasinierTwo=${EtatMagasinierTwo}&EtatValidationB=${EtatValidationB}&EtatDgeneral=V`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getOneRapportProductsDetailsApi = async (ReportID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/rapport/oneRapportProductsDetailsV2/${id}/${iv}/${ReportID}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getOneReportDetailsApi = async (ReportID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/rapport/oneReportDetails/${id}/${iv}/${ReportID}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const updateReportDataApi = async (ReportID, ReportObject) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.put(`${API_URL}/api/rapport/updateRDV2/${id}/${iv}/${ReportID}`, ReportObject, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const updateFCreatedTApi = async (ReportID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.put(`${API_URL}/api/rapport/updateFcreatedTV2/${id}/${iv}/${ReportID}`, {}, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const fixMgReportNumbersApi = async (ReportID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.put(`${API_URL}/api/rapport/MagasinierValidation/${id}/${iv}/${ReportID}`, {}, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const fixInvoceReportNumbersApi = async (ReportID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.put(`${API_URL}/api/rapport/InvoceValidation/${id}/${iv}/${ReportID}`, {}, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}