import API_URL from "../config";
import axios from "axios";

import { getCookie } from "./../utils/cookies";

export const updateCompanyFeesDgStatusApi = async (status, feeId) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
    };

    const formDataObject = {
      dgValidationStatus: status,
    };

    const res = await axios.put(
      `${API_URL}/api/V2/companyFees/updateCompanyFeesDgStatus/${id}/${iv}/${feeId}`,
      formDataObject,
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const updateCompanyFeesTcpStatusApi = async (updatedObject, feeId) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
    };

    const formDataObject = updatedObject;

    const res = await axios.put(
      `${API_URL}/api/V2/companyFees/updateCompanyFeesTcpStatus/${id}/${iv}/${feeId}`,
      formDataObject,
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const updateCompanyFeesPayStatusApi = async (status, feeId) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
    };

    const formDataObject = {
      payeStatus: status,
    };

    const res = await axios.put(
      `${API_URL}/api/V2/companyFees/updateCompanyFeesPayStatus/${id}/${iv}/${feeId}`,
      formDataObject,
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const updateCompanyCompanyFeeApi = async (
  formObjectData,
  selectedFile,
  feeId
) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
    };

    const formData = new FormData();

    formData.append("typeOfOp", formObjectData?.typeOfOp);
    formData.append("date", formObjectData?.date || "");
    formData.append("responsable", formObjectData?.responsable || "");
    formData.append("montant", formObjectData?.montant || "");
    formData.append("description", formObjectData?.description || "");
    formData.append("isJustify", formObjectData?.isJustify || "");

    if (selectedFile) formData.append("bonFile", selectedFile);

    if (formObjectData?.typeOfOp != "FTL")
      formData.append("details", formObjectData?.details || "");

    if (formObjectData?.typeOfOp == "FTL") {
      formData.append("fromCity", formObjectData?.fromCity || "");
      formData.append("toCity", formObjectData?.toCity || "");
    }

    formData.append("modelV", "1.0.1");

    const res = await axios.put(
      `${API_URL}/api/V2/companyFees/updateCompanyCompanyFees/${id}/${iv}/${feeId}`,
      formData,
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const addNewCompanyFeeApi = async (formObjectData, selectedFile) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
    };

    const formData = new FormData();

    formData.append("typeOfOp", formObjectData?.typeOfOp);
    formData.append("date", formObjectData?.date || "");
    formData.append("responsable", formObjectData?.responsable || "");
    formData.append("montant", formObjectData?.montant || "");
    formData.append("description", formObjectData?.description || "");
    formData.append("isJustify", formObjectData?.isJustify || "");

    if (selectedFile) formData.append("bonFile", selectedFile);

    if (formObjectData?.typeOfOp != "FTL")
      formData.append("details", formObjectData?.details || "");

    if (formObjectData?.typeOfOp == "TL") {
      formData.append("fromCity", formObjectData?.fromCity || "");
      formData.append("toCity", formObjectData?.toCity || "");
    }

    formData.append("modelV", "1.0.1");

    const res = await axios.post(
      `${API_URL}/api/V2/companyFees/addNewCompanyFees/${id}/${iv}`,
      formData,
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getAllCompanyFeesApi = async (filtredObject) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.get(
      `${API_URL}/api/V2/companyFees/getAllCompanyFees/${id}/${iv}?typeOfOp=${filtredObject.typeOfOp}&isAproved=${filtredObject.isAproved}&isJustify=${filtredObject.isJustify}&responsable=${filtredObject.responsable}&cvValidationStaus=${filtredObject.cvValidationStaus}&fdValidationStatus=${filtredObject.fdValidationStatus}&tlValidationStatus=${filtredObject.tlValidationStatus}&dgValidationStatus=${filtredObject.dgValidationStatus}&details=${filtredObject.details}&description=${filtredObject.description}&selectedFromDate=${filtredObject.selectedFromDate}&selectedToDate=${filtredObject.selectedToDate}&payeStatus=${filtredObject.payeStatus}`,
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getAllTcpCompanyFeesApi = async (filtredObject) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.get(
      `${API_URL}/api/V2/companyFees/getAllCompanyFees/${id}/${iv}?typeOfOp=${filtredObject.typeOfOp}&responsable=${filtredObject.responsable}&dgValidationStatus=${filtredObject.dgValidationStatus}&selectedFromDate=${filtredObject.selectedFromDate}&selectedToDate=${filtredObject.selectedToDate}`,
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getAllDgCompanyFeesApi = async (filtredObject) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.get(
      `${API_URL}/api/V2/companyFees/getAllCompanyFeesDG/${id}/${iv}?typeOfOp=${filtredObject.typeOfOp}&responsable=${filtredObject.responsable}&dgValidationStatus=${filtredObject.dgValidationStatus}&selectedFromDate=${filtredObject.selectedFromDate}&selectedToDate=${filtredObject.selectedToDate}`,
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const deleteOneCompanyFeesApi = async (companyFeesId) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.delete(
      `${API_URL}/api/V2/companyFees/deleteOneCompanyFees/${id}/${iv}/${companyFeesId}`,
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};
