import React, { useEffect, useState } from "react";
import { Button } from "@material-tailwind/react";
import { getAllRoleNameApi } from "api/operatorRole";
import { addNewOperatorApi } from "api/operator";

import { ToastContainer, toast } from "react-toastify";
import { getAllDepotsApi } from "api/depot";

function NewOp({ closePopup }) {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);

  const [file2, setFile2] = useState(null);
  const [preview2, setPreview2] = useState(null);

  const [operatorRole, setOPeratorRole] = useState([]);

  const [operatorForm, setOperatorForm] = useState();

  const [listOfZone, setListOfZone] = useState([]);

  useEffect(() => {
    allOperatorRoles();
  }, []);

  const allOperatorRoles = async () => {
    try {
      const response = await getAllRoleNameApi();
      setOPeratorRole(response);

      const response2 = await getAllDepotsApi()
      const listOfDepos = []
      response2.map(el => {
        listOfDepos.push({label: el.Name, value: el.Origin})
      })
      setListOfZone(listOfDepos)
    } catch (err) {}
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    // Preview the file
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(selectedFile);
  };

  const handleFileChange2 = (event) => {
    const selectedFile = event.target.files[0];
    setFile2(selectedFile);

    // Preview the file
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview2(reader.result);
    };
    reader.readAsDataURL(selectedFile);
  };

  const handleCancelOp = () => {
    try {
      closePopup("Close");
    } catch (err) {}
  };
  const handleAddNewOp = async () => {
    // console.log("operatorForm", operatorForm)
    // console.log("profile image", file)
    // console.log("signature image", file2)
    try {
      await addNewOperatorApi(operatorForm, file, file2)
      closePopup("Reload")
    } catch (err) {
      toast.error(
        err?.response?.data?.error ||
          "Remplir toutes les données du formulaire",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  const handleOpImageClick = () => {
    document.getElementById("op_image").click();
  };

  const handleSignatureImageClick = () => {
    document.getElementById("signature_image").click();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setOperatorForm((prev) => {
      return { ...prev, [name]: value };
    });
  };

  return (
    <form className="h-[80vh] overflow-scroll md:h-auto md:overflow-auto">
      <ToastContainer />
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
        <div>
          <label htmlFor="op_image" className="text-md font-bold text-navy-700">
            Image de profile
          </label>
          <div>
            <input
              type="file"
              id="op_image"
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept="image/*"
            />
            {!preview && (
              <label htmlFor="op_image" style={{ cursor: "pointer" }}>
                Clicker ici pour sélectionner votre image de profile
              </label>
            )}
            {preview && (
              <div>
                {/* Display the file preview */}
                {file.type.startsWith("image/") ? (
                  <img
                    src={preview}
                    alt="File Preview"
                    onClick={handleOpImageClick}
                    style={{
                      maxWidth: "120px",
                      height: "100px",
                      objectFit: "cover",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <p>File type not supported for preview</p>
                )}
              </div>
            )}
          </div>
        </div>
        <div>
          <label
            htmlFor="signature_image"
            className="text-md font-bold text-navy-700"
          >
            Signature
          </label>
          <div>
            <input
              type="file"
              id="signature_image"
              style={{ display: "none" }}
              onChange={handleFileChange2}
              accept="image/*"
            />
            {!preview2 && (
              <label htmlFor="signature_image" style={{ cursor: "pointer" }}>
                Clicker ici pour sélectionner votre signature
              </label>
            )}
            {preview2 && (
              <div>
                {/* Display the file preview2 */}
                {file2.type.startsWith("image/") ? (
                  <img
                    src={preview2}
                    alt="File preview2"
                    onClick={handleSignatureImageClick}
                    style={{
                      maxWidth: "100%",
                      height: "100px",
                      objectFit: "cover",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <p>File type not supported for preview</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-5 md:grid-cols-1">
        <div>
          <label htmlFor="op_role" className="text-md font-bold text-navy-700">
            Rôle
          </label>
          <select
            label="select Version"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            defaultValue=""
            id="op_role"
            name="userFromRole"
            onChange={handleInputChange}
          >
            <option disabled value="">
              Sélectionner votre role
            </option>
            {operatorRole.map((el) => (
              <option value={el._id}>{el.name}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Nom complet
          </label>
          <input
            type="text"
            id=""
            placeholder="Nom complet"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            name="Name"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Téléphone
          </label>
          <input
            type="text"
            id=""
            placeholder="Téléphone"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            name="Phone"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            E-mail
          </label>
          <input
            type="text"
            id=""
            placeholder="E-mail"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            name="Email"
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            CIN
          </label>
          <input
            type="text"
            id=""
            placeholder="CIN"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            name="Cin"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Code TCP
          </label>
          <input
            type="text"
            id=""
            placeholder="Code TCP (2 charactéres)"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            name="NameCode"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Zone
          </label>
          <select
            label="select Version"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            defaultValue=""
            id="op_role"
            name="Zone"
            onChange={handleInputChange}
          >
            <option disabled value="">
              Sélectionner votre zone
            </option>
            {listOfZone.map((el) => (
              <option value={el.label}>{el.label} ({el.value})</option>
            ))}
          </select>
        </div>
      </div>

      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Numéro d'immactriculation
          </label>
          <input
            type="text"
            id=""
            placeholder="Numéro d'immactriculation"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            name="UserVehicul"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Mot de passe
          </label>
          <input
            type="password"
            id=""
            placeholder="Mot de passe"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            name="Password"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Mot de passe
          </label>
          <input
            type="password"
            id=""
            placeholder="Confirmer le mot de passe"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            name="CPassword"
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="mt-5">
        {/* <Button onClick={() => handleAddNewOp()} className="linear rounded-[10px] bg-green-500 font-bold text-white transition duration-200 hover:bg-green-400 active:bg-green-900 dark:bg-green-400 dark:hover:bg-green-300 dark:active:opacity-90 mr-5">Ajouter</Button>
        <Button onClick={() => handleCancelOp()} className="linear rounded-[10px] bg-gray-500 font-bold text-white transition duration-200 hover:bg-gray-400 active:bg-gray-900 dark:bg-gray-400 dark:hover:bg-gray-300 dark:active:opacity-90">Annuler</Button> */}

        <Button
          variant="gradient"
          color="#236571"
          onClick={() => handleAddNewOp()}
          className="mr-1"
        >
          <span>Ajouter</span>
        </Button>
        <Button
          variant="text"
          color="#cf503f"
          onClick={() => handleCancelOp()}
          className="mr-1"
        >
          <span>Annuler</span>
        </Button>
      </div>
    </form>
  );
}

export default NewOp;
