import { Button, Spinner } from '@material-tailwind/react'
import { getAllDepotsApi } from 'api/depot';
import { getAllProductsDataByCategoryApi } from 'api/filtersData';
import { getAllProductsCategoriesApi } from 'api/filtersData';
import { getAllProductsDataApi } from 'api/filtersData';
import React, { useEffect, useState } from 'react'

import { Combobox } from "@headlessui/react";
import { addNewStockEntryApi } from 'api/stockEntry';
import { updateStockApi } from 'api/stock';
import { updateProductLotNumberApi } from 'api/products';

function NewStockEnterForm({ closePopup, showToast }) {
  const [products, setProducts] = useState([])
  const [categories, setCategories] = useState([])
  const [depots, setDepots] = useState([])
  const [lotNumbers, setLotNumbers] = useState("")
  const [isLoading, setIsLoading] = useState(true)

  const [comboBoxData, setComboBoxData] = useState([]);
  const [selectedComboBoxData, setSelectedComboBoxData] = useState("");
  const [query, setQuery] = useState("");

  const [stockEntryFormData, setStockEntryFormData] = useState({
    SelectedDate: "",
    Category: "",
    Produit: "",
    Quantity: "",
    Depot: "",
    ExperationDate: "",
    LotNumber: ""
  })

  useEffect(() => {
    getAllCategories()
    getAllDepots()
  }, [])

  const filteredComboBoxData =
  query === ""
    ? comboBoxData
    : comboBoxData?.filter((value) =>
        value
          ?.toLowerCase()
          ?.replace(/\s+/g, "")
          ?.includes(query.toLowerCase().replace(/\s+/g, ""))
      );

  const handleInputChange = async (e) => {
    const { name, value } = e.target;

    if(name === "Category") {
      const productCategory = await getAllProductsDataByCategoryApi(value)
      setProducts(productCategory)
    }
    if(name === "Produit") {
      const productsDetails = await getAllProductsDataByCategoryApi(stockEntryFormData.Category)
      const productDetail = productsDetails.find(el => el.Name === value)

      const LotNumbers = []

      productDetail.LotNumber.map(el => {
        if(el.number)
          LotNumbers.push(el.number)
      })

      setComboBoxData(LotNumbers)
      setLotNumbers(LotNumbers)
    }

    setStockEntryFormData({ ...stockEntryFormData, [name]: value });
  };

  const getAllCategories = async () => {
    try {
      setIsLoading(true)
      const categories = await getAllProductsCategoriesApi("")
      setCategories(categories)
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setIsLoading(false)
    }
  }

  const getAllDepots = async () => {
    try {
      setIsLoading(true)
      const depots = await getAllDepotsApi()
      setDepots(depots)
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setIsLoading(false)
    }
  }

  const handleCancelOp = () => {
    try {
      closePopup("Close");
    } catch (err) {}
  };

  const handleAddNewStockEnter = async () => {
    // showToast('success', 'success')
    // showToast('error', 'error')
    try {
      // add new entry form validation
      if(!stockEntryFormData.Category || stockEntryFormData.Category == "")
        return showToast('error', 'Veuillez sélectionner une category')

      if(!stockEntryFormData.Produit || stockEntryFormData.Produit == "")
        return showToast('error', 'Veuillez sélectionner une produit')

      if(!stockEntryFormData.Quantity || stockEntryFormData.Quantity == 0)
        return showToast('error', 'Veuillez entrer une quantité')

      if(!stockEntryFormData.Quantity || stockEntryFormData.Quantity == 0)
        return showToast('error', 'Veuillez sélectionner le numéro de lot')

      setIsLoading(true)
      const selectedProduct = products.find(el => el.Name == stockEntryFormData.Produit)

      const objectData = {
        SelectedDate: stockEntryFormData.SelectedDate,
        CategoryID: stockEntryFormData.Category,
        Produit: stockEntryFormData.Produit,
        Quantity: stockEntryFormData.Quantity,
        Depot: stockEntryFormData.Depot,
        ExperationDate: stockEntryFormData.ExperationDate,
        LotNumber: stockEntryFormData.LotNumber,
        TimeString: Date.now(),

        ProductDetails: selectedProduct,
        Category: selectedProduct.Categorie
      }

      // add new stock entry
      const stockEntryRes = await addNewStockEntryApi(objectData)

      // update product lot number *productID, productCategoryID, lotNumber*
      await updateProductLotNumberApi(selectedProduct._id, stockEntryFormData.Category, stockEntryFormData.LotNumber)

      // update stock
      const selectedProductsList = []
      selectedProductsList.push({
        Name: selectedProduct.Name,
        FromD: null,
        ToD: stockEntryFormData.Depot,
        Quantity: parseFloat(stockEntryFormData.Quantity),
        Type: "StockEnter",
        TypeOfEnter: "Enter",
        FromEntry: stockEntryRes._id
      })
      await updateStockApi(selectedProductsList, "save")

      closePopup("Close")

      showToast('success', 'Ajoutée')
    }
    catch (err) {
      return showToast('error', 'Server error')
    }
    finally {
      setIsLoading(false)
    }
  }

  return (
    <form>
      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-5">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Date :
          </label>
          <input
            type="date"
            id=""
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

            onChange={handleInputChange}
            name="SelectedDate"
            value={stockEntryFormData?.SelectedDate}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Catégorie de produit :
          </label>
          <select
              label="select Version"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              
              onChange={handleInputChange}
              name="Category"
              value={stockEntryFormData?.Category}
            >
              <option disabled value="">Sélectionner votre catégorie</option>
              {
                categories.map(el => (
                  <option value={el._id}>{el.Name}</option>
                ))
              }
            </select>
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Produit :
          </label>
          <select
              label="select Version"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              
              onChange={handleInputChange}
              name="Produit"
              value={stockEntryFormData?.Produit}
            >
              <option value="">Sélectionner votre produit</option>
              {
                products.map(el => (
                  <option value={el.Name}>{el.Name}</option>
                ))
              }
            </select>
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Dépôt :
          </label>
          <select
              label="select Version"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

              onChange={handleInputChange}
              name="Depot"
              value={stockEntryFormData?.Depot}
            >
              <option disabled value="">Sélectionner votre dépôt</option>
              {
                depots.map(el => (
                  <option>{el.Name}</option>
                ))
              }
            </select>
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Quantité :
          </label>
          <input
            type="number"
            id=""
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            min={0}

            onChange={handleInputChange}
            name="Quantity"
            value={stockEntryFormData?.Quantity}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Numéro de lot :
          </label>
          <Combobox
            value={selectedComboBoxData}
            onChange={(value) => {
              setSelectedComboBoxData(value);
              setStockEntryFormData({ ...stockEntryFormData, LotNumber: value });
            }}
          >
            <Combobox.Input
              onChange={(event) => {
                setQuery(event.target.value);
                setSelectedComboBoxData(event.target.value);
                handleInputChange({
                  target: {
                    name: "LotNumber",
                    value: event.target.value
                  }
                });
              }}
              className="relative flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none"
            />
            <Combobox.Options className="absolute left-5 h-32 w-1/2 overflow-y-scroll bg-white">
              {filteredComboBoxData?.map((value, index) => (
                <Combobox.Option
                  key={index}
                  value={value}
                  className="cursor-pointer rounded-xl p-2 hover:bg-gray-100"
                >
                  {value}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Combobox>
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Date d'expiration :
          </label>
          <input
            type="date"
            id=""
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

            onChange={handleInputChange}
            name="ExperationDate"
            value={stockEntryFormData?.ExperationDate}
          />
        </div>
      </div>

      {
        isLoading ?
        <div className="flex w-full items-center justify-center">
          <Spinner className="h-12 w-12" />
        </div> :
        <div className="mt-5">
          <Button
            variant="gradient"
            color="#236571"
            className="mr-1"
            onClick={() => handleAddNewStockEnter()}
          >
            <span>Ajouter</span>
          </Button>
          <Button
            variant="text"
            color="#cf503f"
            className="mr-1"
            onClick={handleCancelOp}
          >
            <span>Annuler</span>
          </Button>
        </div>
      }

    </form>
  )
}

export default NewStockEnterForm
