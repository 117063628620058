import React from 'react'
import DailySellsTable from './components/Daily_sells_table'

function DailySellsList() {
  return (
    <div>
        <DailySellsTable />
    </div>
  )
}

export default DailySellsList
