import API_URL from '../config';
import axios from 'axios';

import { getCookie } from './../utils/cookies';

export const updateRecouvrementDate = async (ReportID, objectData) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const ObjectData = objectData

        const res = await axios.put(`${API_URL}/api/rapport/updateRecouvrementDateV2/${id}/${iv}/${ReportID}`, ObjectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getAllRecouvryApi = async (clientName, typeOfOp, E_I, formDate, toDate, currentYear, clientCode) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/rapport/getRapportRecouvrementSeparateV2/${id}/${iv}?clientName=${clientName}&typeOfOp=${typeOfOp}&E_I=${E_I}&DateEcheance=${formDate == "" || toDate == "" ? "" : `["${formDate}", "${toDate}"]`}&currentYear=${currentYear}&clientCode=${clientCode}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getAllRecouvryXcelApi = async (clientName, typeOfOp, E_I, formDate, toDate, currentYear, clientCode) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/rapport/getRapportRecouvrementSeparateV2Xcel/${id}/${iv}?clientName=${clientName}&typeOfOp=${typeOfOp}&E_I=${E_I}&DateEcheance=${formDate == "" || toDate == "" ? "" : `["${formDate}", "${toDate}"]`}&currentYear=${currentYear}&clientCode=${clientCode}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getAllRecouvryEEApi = async (clientName, typeOfOp, E_I, formDate, toDate, currentYear, clientCode) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/rapport/getRapportRecouvrementEESeparateV2/${id}/${iv}?clientName=${clientName}&typeOfOp=${typeOfOp}&E_I=${E_I}&DateEcheance=${formDate == "" || toDate == "" ? "" : `["${formDate}", "${toDate}"]`}&currentYear=${currentYear}&clientCode=${clientCode}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getAllRecouvryEEXcelApi = async (clientName, typeOfOp, E_I, formDate, toDate, currentYear, clientCode) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/rapport/getRapportRecouvrementEESeparateV2Xcel/${id}/${iv}?clientName=${clientName}&typeOfOp=${typeOfOp}&E_I=${E_I}&DateEcheance=${formDate == "" || toDate == "" ? "" : `["${formDate}", "${toDate}"]`}&currentYear=${currentYear}&clientCode=${clientCode}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const impayerApi = async (ClientCode, rapportID, category, categoryKey) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const ObjectData = {}

        const res = await axios.put(`${API_URL}/api/rapport/impayerV2/${id}/${iv}/${ClientCode}/${rapportID}/${category}/${categoryKey}`, ObjectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const encaisserApi = async (ClientCode, rapportID, category, categoryKey)  => { 
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const ObjectData = {}

        const res = await axios.put(`${API_URL}/api/rapport/encaissedV2/${id}/${iv}/${ClientCode}/${rapportID}/${category}/${categoryKey}`, ObjectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const undoImpayerApi = async (ClientCode, rapportID, category, categoryKey) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const ObjectData = {}

        const res = await axios.put(`${API_URL}/api/rapport/impayerUndoV2/${id}/${iv}/${ClientCode}/${rapportID}/${category}/${categoryKey}`, ObjectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const undoEncaisserApi = async (ClientCode, rapportID, category, categoryKey) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const ObjectData = {}

        const res = await axios.put(`${API_URL}/api/rapport/encaissedUndoV2/${id}/${iv}/${ClientCode}/${rapportID}/${category}/${categoryKey}`, ObjectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}
