import API_URL from '../config';
import axios from 'axios';

import { getCookie } from './../utils/cookies';

export const addNewCategoryApi = async(categoryName) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const objectData = {
            Name: categoryName
        }

        const res = await axios.post(`${API_URL}/api/V2/category/addCategory/${id}/${iv}`, objectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const updateCategoryApi = async(Name, categoryId) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const objectData = {
            Name: Name
        }

        const res = await axios.put(`${API_URL}/api/V2/category/updateCategory/${id}/${iv}/${categoryId}`, objectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getAllCategoriesApi = async (Name = "") => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/category/getAllCategories/${id}/${iv}?Name=${Name}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const deleteCategoryApi = async(categoryId) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.delete(`${API_URL}/api/V2/category/deleteOneCategory/${id}/${iv}/${categoryId}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}
