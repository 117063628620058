import React, { useEffect, useState } from 'react'
import { Alert, Button } from "@material-tailwind/react";
import { pdfBcGeneratorApi, pdfBlGeneratorApi, pdfInvoceGeneratorApi, pdfBaGeneratorApi, pdfBrGeneratorApi, pdfAvoirGeneratorApi } from 'api/pdf';

function OperationReports({ selectedOp }) {
  const [BcReports, setBcReports] = useState([])
  const [BlReports, setBlReports] = useState([])

  useEffect(() => {
    getInitialData()
  }, [selectedOp])

  const getInitialData = () => {
    try {
      setBcReports(Object.keys(selectedOp.Rapport))

      const arrayOfCities = selectedOp.MagasinStock.flat(1).map(el => el.city)
      setBlReports([...new Set(arrayOfCities)])
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleDownloadBcReport = async (id, category, index) => {
    try {
      if(selectedOp.ModeOperation === "Avoir") {
        const response = await pdfBaGeneratorApi(id, category, index)
        window.open(`${response}`, '_blank')
      }
      else {
        const response = await pdfBcGeneratorApi(id, category, index)
        window.open(`${response}`, '_blank')
      }
    }
    catch (err) {

    }
  }

  const handleDownloadBlReport = async (id, category, index) => {
    try {
      if(selectedOp.ModeOperation === "Avoir") {
        const response = await pdfBrGeneratorApi(id, category, index)
        window.open(`${response}`, '_blank')
      }
      else {
        const response = await pdfBlGeneratorApi(id, category, index)
        window.open(`${response}`, '_blank')
      }
    }
    catch (err) {

    }
  }

  const handleDownloadFReport = async (id, category, index) => {
    try {
      if(selectedOp.ModeOperation === "Avoir") {
        const response = await pdfAvoirGeneratorApi(id, category, index)
        window.open(`${response}`, '_blank')
      }
      else {
        const response = await pdfInvoceGeneratorApi(id, category, index)
        window.open(`${response}`, '_blank')
      }
    }
    catch (err) {

    }
  }

  return (
    <div>
      {
        selectedOp.EtatDgeneral === "V" || selectedOp.Dcommercial === "V" ?
        <>
          <Alert variant="gradient" color='brand'>
            <p className='text-center w-full'> Bon de commande </p>
          </Alert>
          <div className='my-5 grid grid-cols-1 gap-5 md:grid-cols-5'>
            {
              BcReports.map((el, index) => (
                <Button variant="gradient" className="rounded-full" color="cyan" onClick={() => handleDownloadBcReport(selectedOp._id, el, index)}>{el}</Button>
              ))
            }
          </div>
        </> : <></>
      }

      {
        selectedOp.EtatMagasinier === "V" &&
        <>
          <Alert variant="gradient" color='brand'>
            <p className='text-center w-full'> Bon de livraison </p>
          </Alert>
          <div className='my-5 grid grid-cols-1 gap-5 md:grid-cols-5'>
            {
              BlReports.map((el, index) => (
                <Button variant="gradient" className="rounded-full" color="cyan" onClick={() => handleDownloadBlReport(selectedOp._id, el, index)}>{el}</Button>
              ))
            }
          </div>
        </>
      }

      {
        selectedOp.EtatFacturation === "V" &&
        <>
          <Alert variant="gradient" color='brand'>
            <p className='text-center w-full'> Factures </p>
          </Alert>
          <div className='my-5 grid grid-cols-1 gap-5 md:grid-cols-5'>
            {
              BcReports.map((el, index) => (
                <Button variant="gradient" className="rounded-full" color="cyan" onClick={() => handleDownloadFReport(selectedOp._id, el, index)}>{el}</Button>
              ))
            }
          </div>
        </>
      }

    </div>
  )
}

export default OperationReports
