import API_URL from '../config';
import axios from 'axios';

import { getCookie } from './../utils/cookies';

export const addNewStockEntryApi = async (objectData) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const ObjectData = objectData

        const res = await axios.post(`${API_URL}/api/V2/stockEnter/addStockEnter/${id}/${iv}`, ObjectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getAllStockEntryApi = async (Produit, LotNumber, selectedFromDate, selectedToDate) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/stockEnter/getAllEnters/${id}/${iv}?Produit=${Produit}&LotNumber=${LotNumber}&selectedFromDate=${selectedFromDate}&selectedToDate=${selectedToDate}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const deleteOneStockEnterApi = async (StockEnterID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.delete(`${API_URL}/api/V2/stockEnter/deleteOneStockEnter/${id}/${iv}/${StockEnterID}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

