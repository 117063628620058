import React, { useEffect, useState } from "react";
import Card from "components/card";

import { generaleTableApi, generaleTableXcelApi } from "api/dashboard";
import { Spinner } from "@material-tailwind/react";
import API_URL from "config";

const GlobaleTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingXcel, setIsLoadingXcel] = useState(false);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [tableData, setTableData] = useState([]);

  const getInitialData = () => {
    try {
      setIsLoading(true);
      if (fromDate && toDate) {
        generaleTableApi(fromDate, toDate)
        .then((generaleTableRes) => {
          setTableData(generaleTableRes);
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setIsLoading(false)
        })
      } else {
        generaleTableApi(null, null)
        .then((generaleTableRes) => {
          setTableData(generaleTableRes);
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setIsLoading(false)
        })
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleFilterData = () => {
    try {
      getInitialData();
    }
    catch (err) {
      
    }
  }

  const handleDownloadXcel = async () => {
    try {
      setIsLoadingXcel(true);
      if (fromDate && toDate) {
        const generalXcelTableRes = await generaleTableXcelApi(
          fromDate,
          toDate
        );

        window.open(`${API_URL}/${generalXcelTableRes}`, "_blank");
      } else {
        const generalXcelTableRes = await generaleTableXcelApi(null, null);

        window.open(`${API_URL}/${generalXcelTableRes}`, "_blank");
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsLoadingXcel(false);
    }
  };

  // Format number with space as thousand separators
  const formatNumber = (value) => {
    return new Intl.NumberFormat('en-EN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  useEffect(() => {
    getInitialData();
  }, []); // fromDate, toDate

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner className="h-12 w-12" />
        </div>
      ) : (
        <Card extra={"w-full pb-10 p-4 h-full"}>
          <header className="relative flex items-center justify-between">
            <div className="text-xl font-bold text-navy-700 dark:text-white">
              TABLE GENERALE
            </div>
            {isLoadingXcel ? (
              <div className="flex items-center justify-center">
                <Spinner className="h-12 w-12" />
              </div>
            ) : (
              <button
                href=""
                className="linear rounded-[10px] bg-green-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-green-400 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                onClick={handleDownloadXcel}
              >
                Télécharger excel
              </button>
            )}
          </header>

          <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-4">
            <div>
              <small className="text-sm font-medium text-gray-600 dark:text-white">
                Filtrer par date
              </small>
              <div>
                <input
                  type="date"
                  id=""
                  placeholder=""
                  className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
                  value={fromDate}
                  onChange={(event) => setFromDate(event.target.value)}
                />
                <input
                  type="date"
                  id=""
                  placeholder=""
                  className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
                  value={toDate}
                  onChange={(event) => setToDate(event.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-4">
            <button
              href=""
              className="linear rounded-[10px] bg-green-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-green-400 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
              onClick={handleFilterData}
            >
              Filtrer
            </button>
          </div>

          <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">TCP</p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Produit
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Catégorie
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600 text-right">
                      Quantité
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600 text-right">
                      Montant HT
                    </p>
                  </th>
                </tr>
              </thead>

              <tbody>
                {tableData?.data
                  ? Object.entries(tableData?.data).map(([key, value]) => {
                      const subQty = 0;
                      const subTotal = 0;

                      const subQtySubTotal = {
                        subQty: 0,
                        subTotal: 0
                      }
                      
                      return (
                        <>
                          {Object.entries(value).map(([key2, value2]) => {
                            const category = value2[0].Category;

                            const subSubQty = value2[value2.length - 1].sumOfQuantity
                            const subSubTotal = value2[value2.length - 1].sumOfAmount

                            subQtySubTotal.subQty = value2[value2.length - 1].sumOfOneTcpQuantity
                            subQtySubTotal.subTotal = value2[value2.length - 1].sumOfOneTcpAmount

                            // Object.entries(value).map(([index, product]) => {
                            //   subQty = parseFloat(product[product.length - 1].sumOfOneTcpQuantity)
                            //   subTotal = parseFloat(product[product.length - 1].sumOfOneTcpAmount)
                            //   // product.map((el) => {
                            //   //   subQty += parseFloat(el.Quantity);
                            //   //   subTotal += parseFloat(el.Amount);
                            //   // });
                            // });
                            return (
                              <tr>
                                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    {key}
                                  </p>
                                </td>
                                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    {key2}
                                  </p>
                                </td>
                                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    {category}
                                  </p>
                                </td>
                                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                                  <p className="text-sm font-bold text-navy-700 dark:text-white text-right mr-1">
                                    {formatNumber(subSubQty.toFixed(2))}
                                  </p>
                                </td>
                                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                                  <p className="text-sm font-bold text-navy-700 dark:text-white text-right mr-1">
                                    {formatNumber(subSubTotal.toFixed(2))}
                                  </p>
                                </td>
                              </tr>
                            );
                          })}

                          <tr className="bg-gray-100 dark:bg-brand-400">
                            <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                              <p className="text-sm font-bold text-navy-700 dark:text-white"></p>
                            </td>
                            <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                              <p className="text-sm font-bold text-navy-700 dark:text-white"></p>
                            </td>
                            <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                              <p className="text-sm font-bold text-navy-700 dark:text-white"></p>
                            </td>
                            <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                              <p className="text-sm font-bold text-navy-700 dark:text-white text-right mr-1">
                                {formatNumber(subQtySubTotal.subQty.toFixed(2))}
                              </p>
                            </td>
                            <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                              <p className="text-sm font-bold text-navy-700 dark:text-white text-right mr-1">
                                {formatNumber(subQtySubTotal.subTotal.toFixed(2))}
                              </p>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  : ""}

                <tr className="mt-5 bg-gray-200 dark:bg-brand-400">
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white"></p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white"></p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white"></p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-[22px] text-sm font-bold text-navy-700 dark:text-white text-right mr-1 font-mono">
                      {formatNumber(tableData?.sumOfQuantity?.toFixed(2))}
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-[22px] text-sm font-bold text-navy-700 dark:text-white text-right mr-1 font-mono">
                      {formatNumber(tableData?.sumOfAmount?.toFixed(2))}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Card>
      )}
    </>
  );
};

export default GlobaleTable;
