import React from 'react'
import DepotMovementsList from './components/Movements_list'

function ProductsMovementsList() {
  return (
    <div>
        <DepotMovementsList />
    </div>
  )
}

export default ProductsMovementsList
