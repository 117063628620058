import API_URL from '../config';
import axios from 'axios';

import { getCookie } from './../utils/cookies';

export const getAllEssayReportsApi = async (filtredObject) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/essay/getAllEssayReports/${id}/${iv}?ClientName=${filtredObject.ClientName}&Variete=${filtredObject.Variete}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getOneEssayReportsApi = async (reportID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/essay/getOneEssayReports/${id}/${iv}/${reportID}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const updateOneEssayLocationReportsApi = async (reportID, data) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const objectData = {
            localisation: data
        }

        const res = await axios.put(`${API_URL}/api/V2/essay/updateOneEssayLocationReports/${id}/${iv}/${reportID}`,objectData ,option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const updateOneEssayReportsApi = async (reportID, data) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const objectData = data

        const res = await axios.put(`${API_URL}/api/V2/essay/updateOneEssayReports/${id}/${iv}/${reportID}?timestamps=disabled`, objectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const updateOneEssayStatusReportsApi = async (reportID, status, comment) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const objectData = {
            status: status,
            Comment: comment
        }

        const res = await axios.put(`${API_URL}/api/V2/essay/updateOneEssayStatusReports/${id}/${iv}/${reportID}`,objectData ,option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const updateIneUpdatedEssayReportsApi = async (reportID, reportObject) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const objectData = {
            updatedCreatedDate: reportObject.createdAt,
            description: reportObject.description,
            fromUser: reportObject.fromUser
        }

        const res = await axios.put(`${API_URL}/api/V2/essay/updateOneUpdatedEssayReports/${id}/${iv}/${reportID}`, objectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const deleteOneUpdatedEssayReportsApi = async (reportID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.delete(`${API_URL}/api/V2/essay/deleteOneUpdatedEssayReports/${id}/${iv}/${reportID}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

