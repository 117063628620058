import React, { useEffect, useState } from "react";
import Card from "components/card";
import { getLVJApi } from "api/accounting";
import { getAllTcpsApi } from "api/filtersData";
import { Button, Spinner, IconButton } from "@material-tailwind/react";

import {
  IoMdArrowDropleftCircle,
  IoMdArrowDroprightCircle,
} from "react-icons/io";
import { getLVJxcelApi } from "api/accounting";
import API_URL from "config";

function DailySellsTable() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingXcel, setIsLoadingXcel] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [inputFilterValue, setInputFilterValue] = useState({
    TCP: "",
    Client: "",
    CodeClient: "",
    SelectedInvoce: "",
    FromYear: `${new Date().getFullYear()}-01-01`,
    ToYear: `${new Date().getFullYear() + 1}-01-01`,
    Type: "LVJ",
  });

  // paginations
  const [active, setActive] = useState(1);
  const [currentPagination, setCurrentPagination] = useState(1);
  const [listOfTcp, setListOfTcp] = useState([]);

  const [numberOfPages, setNumberOfPages] = useState(0);
  const [numberOfPaginations, setNumberOfPaginations] = useState(0);
  const [numberOfRows, setNumberOfRows] = useState(0);

  useEffect(() => {
    getFiltersData();
  }, []);

  useEffect(() => {
    getTableData(inputFilterValue, active);
  }, []);

  const handleXcelDownload = async () => {
    try {
      setIsLoadingXcel(true);
      const response = await getLVJxcelApi(inputFilterValue);

      await new Promise((resolve) => {
        setTimeout(() => {
          window.open(`${API_URL}/${response}`, "_blank");
          resolve();
        }, 1000);
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingXcel(false);
    }
  };

  const getTableData = async (inputFilterValue, page) => {
    try {
      setIsLoading(true);

      const response = await getLVJApi(inputFilterValue, page);
      setTableData(response.data);

      // paginations
      setNumberOfPages(response.pagination.numberOfPages);
      setNumberOfPaginations(response.pagination.numberOfPaginations);
      setNumberOfRows(response.pagination.numberOfRows);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getFiltersData = async () => {
    try {
      const TcpData = await getAllTcpsApi();
      setListOfTcp(TcpData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFiltersInputChange = (e) => {
    const { name, value } = e.target;
    setInputFilterValue((prevInputFilterValue) => ({
      ...prevInputFilterValue,
      [name]: value,
    }));

    // name === 'Depot' ? value : inputFilterValue.Depot,

    if (name === "TCP") inputFilterValue.TCP = value;

    if (name === "Client") inputFilterValue.Client = value.trim();

    if (name === "CodeClient") inputFilterValue.CodeClient = value.trim();

    if (name === "SelectedInvoce")
      inputFilterValue.SelectedInvoce = value.trim();

    if (name === "FromYear") inputFilterValue.FromYear = value;

    if (name === "ToYear") inputFilterValue.ToYear = value;

    getTableData(inputFilterValue, active);
  };

  // pagination
  const getItemProps = (index) => ({
    variant: active === index ? "filled" : "text",
    color: "gray",
    onClick: () => {
      getTableData(inputFilterValue, index);
      setActive(index);
    },
  });

  const next = () => {
    if (currentPagination === numberOfPaginations) return;
    setCurrentPagination(currentPagination + 1);
  };

  const prev = () => {
    if (currentPagination === 1) return;
    setCurrentPagination(currentPagination - 1);
  };
  // pagination

  return (
    <Card extra={"w-full pb-10 p-4 h-full"}>
      <header className="relative flex flex-col items-center justify-between md:flex-row">
        <div className="mb-5 text-xl font-bold text-navy-700 dark:text-white md:mb-0"></div>
        {
          isLoadingXcel ?
          <div className="flex items-center justify-center">
          <Spinner className="h-12 w-12" />
        </div> :
        <Button
        className="linear mb-5 rounded-[10px] bg-green-500 px-4 py-2 text-xs font-bold text-white transition duration-200 hover:bg-green-400 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:mb-0"
        onClick={handleXcelDownload}
        >
        Télécharger excel
      </Button>
        }
      </header>
      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-5">
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Filtrer par TCP :
          </small>
          <div>
            <select
              label="select Version"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              defaultValue=""
              name="TCP"
              value={inputFilterValue.TCP}
              onChange={handleFiltersInputChange}
            >
              <option value="">Tout</option>
              {listOfTcp.map((el) => (
                <option value={el._id}>{el.Name}</option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Référence facture :
          </small>
          <div>
            <input
              type="text"
              id=""
              placeholder="Tout"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="SelectedInvoce"
              value={inputFilterValue.SelectedInvoce}
              onChange={handleFiltersInputChange}
            />
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Client :
          </small>
          <div>
            <input
              type="text"
              id=""
              placeholder="Tout"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="Client"
              value={inputFilterValue.Client}
              onChange={handleFiltersInputChange}
            />
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Code client :
          </small>
          <div>
            <input
              type="text"
              id=""
              placeholder="Tout"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="CodeClient"
              value={inputFilterValue.CodeClient}
              onChange={handleFiltersInputChange}
            />
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Période (Depuis - à) :
          </small>
          <div>
            <input
              type="date"
              id=""
              placeholder=""
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="FromYear"
              value={inputFilterValue.FromYear}
              onChange={handleFiltersInputChange}
            />
            <input
              type="date"
              id=""
              placeholder=""
              className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="ToYear"
              value={inputFilterValue.ToYear}
              onChange={handleFiltersInputChange}
            />
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="flex w-full items-center justify-center">
          <Spinner className="h-12 w-12" />
        </div>
      ) : (
        <>
          <div
            className="mt-8 overflow-x-scroll"
            style={{ height: "40vh", overflowY: "scroll" }}
          >
            <table className="w-full">
              <thead>
                <tr>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">Date</p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Référence facture
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Catégorie produit
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Nom de produit
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Nom du client
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">TVA</p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">Total TVA</p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">Total HT</p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">Total TTC</p>
                  </th>
                </tr>
              </thead>

              <tbody>
                {tableData.map((el) => (
                  <tr>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.Date}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.InvoceNumber}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.ProductCategory}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.ProductName}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.ClientName}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.Tva} %
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.TvaV}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.HT}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.TTC}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {
            <div className="mt-4 flex items-center gap-4">
              <Button
                variant="text"
                className="flex items-center gap-2"
                onClick={prev}
                disabled={currentPagination === 1}
              >
                <IoMdArrowDropleftCircle size={30} />
              </Button>
              <div className="flex items-center gap-2">
                {currentPagination !== numberOfPaginations
                  ? // paginations row
                    Array.from({
                      length: numberOfPages > 5 ? 5 : numberOfPages,
                    }).map((el, index) => {
                      const pageNumber =
                        index + 1 + (currentPagination - 1) * 10;
                      return (
                        <IconButton {...getItemProps(pageNumber)}>
                          {pageNumber}
                        </IconButton>
                      );
                    })
                  : // last pagination row
                    Array.from({ length: numberOfPages % 10 }).map(
                      (el, index) => {
                        const pageNumber =
                          index + 1 + (currentPagination - 1) * 5;
                        return (
                          <IconButton {...getItemProps(pageNumber)}>
                            {pageNumber}
                          </IconButton>
                        );
                      }
                    )}
              </div>
              <Button
                variant="text"
                className="flex items-center gap-2"
                onClick={next}
                disabled={
                  currentPagination === numberOfPaginations ||
                  numberOfPaginations === 0
                }
              >
                <IoMdArrowDroprightCircle size={30} />
              </Button>
            </div>
          }
        </>
      )}
    </Card>
  );
}

export default DailySellsTable;
