import React, { useEffect, useState } from 'react'
import { Button } from "@material-tailwind/react";
import { updateOneEssayReportsApi, updateOneEssayLocationReportsApi } from 'api/essay';

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function EssayEditeForm({ reportDetailsData, handleCancelForm, showToast }) {
  const [locationData, setLocationData] = useState("");

  const [essayFormData, setEssayFormData] = useState({
    ClientName: null,
    Domain: null,
    Adress: null,
    Gerant: null,
    Phone: null,

    temoinOne: null,
    temoinTwo: null,
    dateInstallationEssai: null,
    dateDeRecolteEstimee: null,
  })

  const [essayFormLoacationData, setEssayFormLoacationData] = useState({
    localisation: null,
  })

  useEffect(() => {
    if(reportDetailsData?.Rapport?.InfoVisiteEssay)
      getInitialData()
  }, [reportDetailsData])

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().slice(0, 16);
  };

  const getInitialData = () => {
    try {
      const {ClientName, Domain, Adress, Gerant, Phone, temoinOne, temoinTwo, dateInstallationEssai, dateDeRecolteEstimee, localisation} = reportDetailsData.Rapport.InfoVisiteEssay

      setEssayFormData({
        ClientName: ClientName ? ClientName : '',
        Domain: Domain ? Domain : '',
        Adress: Adress ? Adress : '',
        Gerant: Gerant ? Gerant : '',
        Phone: Phone ? Phone : '',
        temoinOne: temoinOne || '',
        temoinTwo: temoinTwo || '',
        dateInstallationEssai: dateInstallationEssai ? formatDate(dateInstallationEssai) : '',
        dateDeRecolteEstimee: dateDeRecolteEstimee ? formatDate(dateDeRecolteEstimee) : '',
      });

      setEssayFormLoacationData(localisation)
    }
    catch (err) {
      console.error('Error setting initial data:', err);
    }
  }

  const handleUpdateData = async () => {
    try {
      essayFormData.dateInstallationEssai = new Date(essayFormData.dateInstallationEssai).toISOString()
      essayFormData.dateDeRecolteEstimee = new Date(essayFormData.dateDeRecolteEstimee).toISOString()

      await updateOneEssayReportsApi(reportDetailsData._id, essayFormData)

      Swal.fire("Modifiée", "", "success");
      showToast("success", "Modifiée")
    }
    catch (err) {
      showToast("error", err)
      console.log(err)
    }
  }

  const handleUpdateLocationData = () => {
    try {
      withReactContent(Swal)
      .fire({
        icon: "warning",
        title: "Voulez-vous vraiment modifier ?",
        confirmButtonText: "Modifier",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const url = essayFormLoacationData.localisation

            const regex = new RegExp('@(.*),(.*),');
            const lat_long_match = url.match(regex);

            if(lat_long_match) {
              const lat = lat_long_match[1];
              const long = lat_long_match[2];

              const myLocation = `${lat}, ${long}`

              await updateOneEssayLocationReportsApi(reportDetailsData._id, myLocation)
              Swal.fire("Modifiée", "", "success");
              showToast("success", "Modifiée")
            }
            else {
              Swal.fire("Cesser une lien correct", "", "error");
            }
          } catch (err) {
            console.log(err)
            Swal.fire("Cesser une lien correct", "", "error");
          } finally {
          }
        }
      })
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleCancelData = () => {
    try {
      handleCancelForm()
    }
    catch (err) {

    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEssayFormData({ ...essayFormData, [name]: value });
  };

  const handleLoacationInputChange = (e) => {
    const { name, value } = e.target;
    setEssayFormLoacationData({ ...essayFormLoacationData, [name]: value });
  };

  return (
    <form style={{
      overflow: 'scroll',
      height: '80vh'
    }}>

    <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Nom client
          </label>
          <input
            type="text"
            id=""
            placeholder="Nom Client"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

            value={essayFormData?.ClientName}
            name="ClientName"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Domaine
          </label>
          <input
            type="text"
            id=""
            placeholder="Domaine"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

            value={essayFormData?.Domain}
            name="Domain"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Adresse
          </label>
          <input
            type="text"
            id=""
            placeholder="Adresse"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

            value={essayFormData?.Adress}
            name="Adress"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Gérant
          </label>
          <input
            type="text"
            id=""
            placeholder="Gérant"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

            value={essayFormData?.Gerant}
            name="Gerant"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Téléphone
          </label>
          <input
            type="text"
            id=""
            placeholder="Numéro de téléphone"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

            value={essayFormData?.Phone}
            name="Phone"
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Témoin 1
          </label>
          <input
            type="text"
            id=""
            placeholder="Témoin 1"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

            value={essayFormData?.temoinOne}
            name="temoinOne"
            onChange={handleInputChange}
          />
        </div>

        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Témoin 2
          </label>
          <input
            type="text"
            id=""
            placeholder="Témoin 2"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

            value={essayFormData?.temoinTwo}
            name="temoinTwo"
            onChange={handleInputChange}
          />
        </div>

        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Date installation
          </label>
          <input
            type="datetime-local"
            id=""
            placeholder="Date installation"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

            value={essayFormData?.dateInstallationEssai || ''}
            name="dateInstallationEssai"
            onChange={handleInputChange}
          />
        </div>

        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Date récolte
          </label>
          <input
            type="datetime-local"
            id=""
            placeholder="Date recolte"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

            value={essayFormData?.dateDeRecolteEstimee || ''}
            name="dateDeRecolteEstimee"
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="mt-5">
          <Button
            variant="gradient"
            color="#236571"
            onClick={() => handleUpdateData()}
            className="mr-1"
          >
            <span>Modifier</span>
          </Button>
          <Button
            variant="text"
            color="#cf503f"
            onClick={() => handleCancelData()}
            className="mr-1"
          >
            <span>Annuler</span>
          </Button>
        </div>

        <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-1">
          <div className="mt-5">
            <input
              type="text"
              id=""
              placeholder="saisir votre localisation"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

              value={essayFormLoacationData?.localisation}
              name="localisation"
              onChange={handleLoacationInputChange}
            />
          </div>
          <div className="mt-5">
            <Button
              variant="gradient"
              color="#236571"
              onClick={() => handleUpdateLocationData()}
              className="mr-1"
            >
              <span>Modifier votre localisation</span>
            </Button>
          </div>
        </div>
    </form>
  )
}

export default EssayEditeForm
