import API_URL from '../config';
import axios from 'axios';

import { getCookie } from './../utils/cookies';

export const getAllProductsApi = async(Name, Code, Categorie) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/products/getAllProducts/${id}/${iv}?Name=${Name}&Code=${Code}&Categorie=${Categorie}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const addNewProductApi = async (productObject) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.post(`${API_URL}/api/V2/products/addProduct/${id}/${iv}`, productObject, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getOneProductApi = async (productID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/products/getOneProduct/${id}/${iv}/${productID}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const updateProductApi = async (productObject, productID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.put(`${API_URL}/api/V2/products/updateProduct/${id}/${iv}/${productID}`, productObject, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const updateProductStatusApi = async (productID, productCategoryID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.put(`${API_URL}/api/V2/products/updateProductStatus/${id}/${iv}/${productID}/${productCategoryID}`, {}, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const updateProductLotNumberApi = async (productID, productCategoryID, lotNumber) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const objectData = {
            LotNumber: lotNumber
        }

        const res = await axios.put(`${API_URL}/api/V2/products/updateProductLotNumber/${id}/${iv}/${productID}/${productCategoryID}`, objectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const deleteOneProductApi = async (productID, categoryID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.delete(`${API_URL}/api/V2/products/deleteOneProduct/${id}/${iv}/${productID}/${categoryID}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const productInvetaireListApi = async (filtredObject) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/products/productInvetaireList/${id}/${iv}?Product=${filtredObject.Product}&category=${filtredObject.Categorie}&Depot=${filtredObject.Depot}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getAllNonCounterDepots = async () => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/products/getAllNonCounterDepots/${id}/${iv}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}
