import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CompanyFeesTable from "./components/Company_fees_table";

import { getCookie } from "utils/cookies";
import { getOneOperatorApi } from "api/operator";
import { Spinner } from "@material-tailwind/react";

function CompanyFees() {
  const { userID } = useParams();
  const [accessList, setAccessList] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAccessList();
  }, []);

  const getAccessList = async () => {
    try {
      setIsLoading(true);

      const id = getCookie("3F2Mb2pJ1YRro5Z4");
      const adminRole = getCookie("lv9u765qzk1gr4fn1e");

      if (adminRole === "FALSE") {
        const response = await getOneOperatorApi(id);
        const accessList = response.userFromRole.access;

        const selectedAccessDetails = accessList.find(
          (el) => el.value._id == userID
        );

        const accessObject = {
          Add: false,
          Read: false,
          Update: false,
          Delete: false,

          VehicleCharges: false,
          TravellingExpenses: false,
          LocalTransportationCosts: false,
        };

        selectedAccessDetails.sectionItems.map((el) => {
          if (el.value == "Add" && el.checked) {
            accessObject.Add = true;
          }
          if (el.value == "Read" && el.checked) {
            accessObject.Read = true;
          }
          if (el.value == "Update" && el.checked) {
            accessObject.Update = true;
          }
          if (el.value == "Delete" && el.checked) {
            accessObject.Delete = true;
          }

          if(el.value == "VehicleCharges" && el.checked) {
            accessObject.VehicleCharges = true
          }
          if(el.value == "TravellingExpenses" && el.checked) {
            accessObject.TravellingExpenses = true
          }
          if(el.value == "LocalTransportationCosts" && el.checked) {
            accessObject.LocalTransportationCosts = true
          }
        });

        setAccessList({
          Add: accessObject.Add,
          Read: accessObject.Read,
          Update: accessObject.Update,
          Delete: accessObject.Delete,

          VehicleCharges: accessObject.VehicleCharges,
          TravellingExpenses: accessObject.TravellingExpenses,
          LocalTransportationCosts: accessObject.LocalTransportationCosts,
        });
      } else if (adminRole === "TRUE") {
        setAccessList({
          Add: true,
          Read: true,
          Update: true,
          Delete: true,

          VehicleCharges: true,
          TravellingExpenses: true,
          LocalTransportationCosts: true,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner className="h-12 w-12" />
        </div>
      ) : (
        <CompanyFeesTable accessList={accessList} />
      )}
    </div>
  );
}

export default CompanyFees;
