import React, { useState } from "react";
import { Button } from "@material-tailwind/react";
import { addNewDepotApi } from "api/depot";

import { ToastContainer, toast } from "react-toastify";

function NewDepot({ closePopup, showToast }) {
  const [depotShortName, setDepotShortName] = useState('')
  const [depotName, setDepotName] = useState('')

  const hadleShowToast = (type, message) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleAddDepot = async () => {
    try {
      await addNewDepotApi(depotShortName, depotName)
      hadleShowToast('success', 'Dépôt ajoutée')
    }
    catch (err) {
      hadleShowToast('error', `${err?.response?.data?.error}`)
    }
  };
  const handleCancelOp = () => {
    closePopup("Close");
  };

  return (
    <form>
      <ToastContainer />
      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Nom du dépôt
          </label>
          <input
            type="text"
            id=""
            placeholder="Nom du dépôt (en 3 caractères)"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            maxLength={3}

            onChange={e => setDepotShortName(e.target.value)}
          />
        </div>

        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Nom complet du dépôt
          </label>
          <input
            type="text"
            id=""
            placeholder="Nom complete du dépôt"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

            onChange={e => setDepotName(e.target.value)}
          />
        </div>
      </div>

      <div className="mt-5">
        <Button
          variant="gradient"
          color="#236571"
          onClick={() => handleAddDepot()}
          className="mr-1"
        >
          <span>Ajouter</span>
        </Button>
        <Button
          variant="text"
          color="#cf503f"
          onClick={() => handleCancelOp()}
          className="mr-1"
        >
          <span>Annuler</span>
        </Button>
      </div>
    </form>
  );
}

export default NewDepot;
