import { Button, Radio } from "@material-tailwind/react";
import { addNewCompanyFeeApi, updateCompanyCompanyFeeApi } from "api/companyFees";
import { getAllOperatorsApi } from "api/filtersData";
import API_URL from "config";
import React, { useEffect, useState } from "react";

import { MdPictureAsPdf } from "react-icons/md";

function CVModale({ closePopup, actionType, selectedData, showToast }) {
  const [listOfTcp, setListOfTcp] = useState([]);

  const [formInputValue, setFormInputValue] = useState({
    details: "",
    date: "",
    responsable: "",
    montant: "",
    description: "",
    isJustify: "true",

    vehicule: "",
    typeOfOp: "CV"
  });

  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    getFilterData();
  }, []);

  useEffect(() => {
    getFormData()
  }, [selectedData])

  const urlToFile = async (url) => {
    try {
      // Fetch the file from the URL
      const response = await fetch(url);
      const blob = await response.blob();

      // Extract file name from URL
      const fileName = url.substring(url.lastIndexOf("/") + 1);

      // Create a new File object
      const file = new File([blob], fileName, {
        type: blob.type,
        lastModified: new Date().getTime(),
      });

      return file;
    } catch (error) {
      console.error("Error converting URL to File:", error);
      return null;
    }
  };

  const getFormData = () => {
    try {
      if(actionType !== "new") {
        setFormInputValue({
          details: selectedData?.details,
          date: selectedData?.date && !isNaN(new Date(selectedData.date)) ? selectedData?.date : "",
          responsable: selectedData?.responsable._id,
          montant: selectedData?.montant,
          description: selectedData?.description,
          isJustify: selectedData?.isJustify ? "true" : "false",

          vehicule: selectedData?.responsable?.UserVehicul,
          typeOfOp: "CV"
        })

        const selectedFile = selectedData?.bonFile
        ? `${API_URL}/${selectedData?.bonFile}`
        : null;

        if(selectedFile) {
          urlToFile(selectedFile).then((file) => {
            // console.log('Converted File:', file);
            setFile(file);
  
            // Preview the file
            const reader = new FileReader();
            reader.onloadend = () => {
              setPreview(reader.result);
            };
            reader.readAsDataURL(file);
          });
        }
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleFiltersInputChange = (e) => {
    const { name, value } = e.target;
    setFormInputValue({
      ...formInputValue,
      [name]: value,
    });

    if (name === "details") formInputValue.details = value;
    if (name === "date") formInputValue.date = value;
    if (name === "responsable") {
      formInputValue.responsable = value;
      const selectedOperator = listOfTcp.find(el => el._id == value)
      setFormInputValue({
        ...formInputValue,
        "vehicule": selectedOperator.UserVehicul
      });
    }
    if (name === "montant") formInputValue.montant = value;
    if (name === "description") formInputValue.description = value;
    if (name === "isJustify") formInputValue.isJustify = value;
  };

  const getFilterData = async () => {
    try {
      const response = await getAllOperatorsApi();
      setListOfTcp(response);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    // Preview the file
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(selectedFile);
  };

  const handleOpImageClick = () => {
    document.getElementById("op_image").click();
  };

  const handleAddNewFees = async () => {
    try {
      if(new Date(formInputValue.date).getFullYear().toString().length > 4)
        return showToast("error", "Date invalide");

      await addNewCompanyFeeApi(formInputValue, file)
      showToast("success", "Ajoutée");
    } catch (err) {
      showToast("error", err?.response?.data?.error);
    }
  };

  const handleUpdateFees = async () => {
    try {
      await updateCompanyCompanyFeeApi(formInputValue, file, selectedData._id)
      showToast("success", "Modifiée");
    }
    catch (err) {
      showToast("error", err?.response?.data?.error);
    }
  }

  const handleEditeFees = () => {
    try {
    } catch (err) {}
  };

  const handleCancelOp = () => {
    closePopup("Close");
  };

  return (
    <div>
      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-2">
        <div>
          <div>
            <label htmlFor="" className="text-md font-bold text-navy-700">
              Détails :
            </label>
            <select
              label="select Version"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="details"
              value={formInputValue.details}
              onChange={handleFiltersInputChange}

              disabled={actionType === "show"}
            >
              <option value=""></option>
              <option value="VehicleCharges">Charges véhicules</option>
              <option value="Maintenance">Maintenance</option>
              <option value="Gasoil">Gasoil</option>
              <option value="Jawaz">Jawaz</option>
            </select>
          </div>
          <div className="mt-4">
            <label htmlFor="" className="text-md font-bold text-navy-700">
              Date :
            </label>
            <input
              type="date"
              id=""
              placeholder="Date"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="date"
              value={
                actionType === "new"
                ? formInputValue.date
                : formInputValue.date.split("T")[0]
              }
              onChange={handleFiltersInputChange}
            />
          </div>
          <div className="mt-4">
            <label htmlFor="" className="text-md font-bold text-navy-700">
              Responsable :
            </label>
            <select
              label="select Version"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="responsable"
              value={formInputValue.responsable}
              onChange={handleFiltersInputChange}
              disabled={actionType === "show"}
            >
              <option value=""></option>
              {listOfTcp.map((el) => (
                <option value={el._id}>{el.Name}</option>
              ))}
            </select>
          </div>
          <div className="mt-4">
            <label htmlFor="" className="text-md font-bold text-navy-700">
              Véhicule :
            </label>
            <input
              type="text"
              id=""
              placeholder="aucun" // 60971-A-34
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              disabled
              value={formInputValue.vehicule}
            />
          </div>
          <div className="mt-4">
            <label htmlFor="" className="text-md font-bold text-navy-700">
              Montant :
            </label>
            <input
              type="number"
              id=""
              placeholder=""
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="montant"
              value={formInputValue.montant}
              onChange={handleFiltersInputChange}
              disabled={actionType === "show"}
            />
          </div>
        </div>

        <div>
          <div>
            <label htmlFor="" className="text-md font-bold text-navy-700">
              Description :
            </label>

            <textarea
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              rows={5}
              name="description"
              value={formInputValue.description}
              onChange={handleFiltersInputChange}
              disabled={actionType === "show"}
            ></textarea>
          </div>
          <div className="mt-4">
            <label
              htmlFor=""
              className="text-md font-bold text-navy-700"
            ></label>
            <div className="flex gap-10">
              <Radio
                name="isJustify"
                value={true}
                onChange={handleFiltersInputChange}
                label="Justifier"
                checked={formInputValue.isJustify === "true"}
                disabled={actionType === "show"}
              />
              <Radio
                name="isJustify"
                value={false}
                onChange={handleFiltersInputChange}
                label="Non justifier"
                checked={formInputValue.isJustify === "false"}
                disabled={actionType === "show"}
              />
            </div>
          </div>
          <div className="mt-4">
            <input
              type="file"
              id="op_image"
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept="image/*, .pdf, .doc, .docx, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            />
            {!preview && (
              <label
                htmlFor="op_image"
                style={{ cursor: "pointer" }}
                className="text-grey-500 rounded-full border-0 bg-blue-50 py-2 px-6 text-sm font-medium text-blue-700 hover:cursor-pointer hover:bg-amber-50 hover:text-amber-700"
              >
                Clicker ici pour sélectionner votre document
              </label>
            )}
            {preview && (
              <div>
                {/* Display the file preview: image/ - application/pdf */}
                {file.type.startsWith("image/") ? (
                  <img
                    src={preview}
                    alt="File Preview"
                    onClick={handleOpImageClick}
                    style={{
                      maxWidth: "120px",
                      height: "100px",
                      objectFit: "cover",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <div>
                    <MdPictureAsPdf
                      onClick={handleOpImageClick}
                      style={{ cursor: "pointer" }}
                      size={100}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mt-5">
        {
          actionType == "new" &&
            <Button
              variant="gradient"
              color="#236571"
              onClick={() => handleAddNewFees()}
              className="mr-1"
            >
              <span>Ajouter</span>
            </Button>
        }
        {
          actionType == "update" &&
            <Button
              variant="gradient"
              color="#236571"
              onClick={() => handleUpdateFees()}
              className="mr-1"
            >
              <span>Modifier</span>
            </Button>
        }
        <Button
          variant="text"
          color="#cf503f"
          onClick={() => handleCancelOp()}
          className="mr-1"
        >
          <span>Annuler</span>
        </Button>
      </div>
    </div>
  );
}

export default CVModale;
