import React from 'react'
import DailySellsJrlTable from './components/Daily_sells_jrl'

function DailySellsJrl() {
  return (
    <div>
        <DailySellsJrlTable />
    </div>
  )
}

export default DailySellsJrl
