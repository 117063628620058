import API_URL from "../config";
import axios from "axios";

import { getCookie } from "./../utils/cookies";

export const getAllTcpsApi = async () => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.get(
      `${API_URL}/api/V2/filters/Tcps/${id}/${iv}`,
      option
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getAllOperatorsApi = async () => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.get(
      `${API_URL}/api/V2/filters/Operators/${id}/${iv}`,
      option
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getAllClientsApi = async () => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.get(
      `${API_URL}/api/V2/filters/Clients/${id}/${iv}`,
      option
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getAllClientsApi2 = async () => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.get(
      `${API_URL}/api/V2/filters/AllClients/${id}/${iv}`,
      option
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getAllProductsNameApi = async () => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.get(
      `${API_URL}/api/V2/filters/Products/${id}/${iv}`,
      option
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getAllProductsDataApi = async () => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.get(
      `${API_URL}/api/V2/filters/ProductsData/${id}/${iv}`,
      option
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getAllProductsDataByCategoryApi = async (CategoryID) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.get(
      `${API_URL}/api/V2/filters/ProductsDataByCategory/${id}/${iv}/${CategoryID}`,
      option
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getAllProductsCategoriesApi = async (Name) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.get(
      `${API_URL}/api/V2/category/getAllCategories/${id}/${iv}?Name=${Name}`,
      option
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const operatorsAccessList = async () => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.get(
      `${API_URL}/api/V2/filters/SideBar/${id}/${iv}`,
      option
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getAllZoneListApi = async () => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.get(
      `${API_URL}/api/V2/filters/SideBar/${id}/${iv}`,
      option
    );
    return res.data;
  } catch (err) {
    return err;
  }
};
