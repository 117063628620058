import API_URL from '../config';
import axios from 'axios';

import { getCookie } from './../utils/cookies';

export const ckeckRoleBeforeEdite = async (name, access, mobileAccess, mobileAccessObject, roleID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const objectData = {
            "name": name,
            "access": access
        }

        if(mobileAccess) {
            objectData.isDG = mobileAccessObject.isDG
            objectData.isDC = mobileAccessObject.isDC
            objectData.isC = mobileAccessObject.isC
            objectData.isTD = mobileAccessObject.isTD
        }

        const res = await axios.post(`${API_URL}/api/V2/operatorsRoles/ckeckRoleBeforeEdite/${id}/${iv}/${roleID}`, objectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const ckeckRoleBeforeAdd = async (name, access, mobileAccess, mobileAccessObject) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const objectData = {
            "name": name,
            "access": access
        }

        if(mobileAccess) {
            objectData.isDG = mobileAccessObject.isDG
            objectData.isDC = mobileAccessObject.isDC
            objectData.isC = mobileAccessObject.isC
            objectData.isTD = mobileAccessObject.isTD
        }

        const res = await axios.post(`${API_URL}/api/V2/operatorsRoles/ckeckRoleBeforeAdd/${id}/${iv}`, objectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const addNewRoleApi = async(name, access, mobileAccess, mobileAccessObject) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const objectData = {
            "name": name,
            "access": access
        }

        if(mobileAccess) {
            objectData.isDG = mobileAccessObject.isDG
            objectData.isDC = mobileAccessObject.isDC
            objectData.isC = mobileAccessObject.isC
            objectData.isTD = mobileAccessObject.isTD
        }

        const res = await axios.post(`${API_URL}/api/V2/operatorsRoles/add/${id}/${iv}`, objectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const updateRoleApi = async(name, access, mobileAccess, mobileAccessObject, roleID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const objectData = {
            "name": name,
            "access": access
        }

        if(mobileAccess) {
            objectData.isDG = mobileAccessObject.isDG
            objectData.isDC = mobileAccessObject.isDC
            objectData.isC = mobileAccessObject.isC
            objectData.isTD = mobileAccessObject.isTD
        }
        else if(!mobileAccess) {
            objectData.isDG = false
            objectData.isDC = false
            objectData.isC = false
            objectData.isTD = false
        }

        const res = await axios.put(`${API_URL}/api/V2/operatorsRoles/updateOne/${id}/${iv}/${roleID}`, objectData, option);
        return res.data
    }
    catch (err) {
        return err
    }
}

export const updateSignatureRoleApi = async(signature, roleID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const objectData = {
            "signature": signature,
        }

        const res = await axios.put(`${API_URL}/api/V2/operatorsRoles/updateOne/${id}/${iv}/${roleID}`, objectData, option);
        return res.data
    }
    catch (err) {
        return err
    }
}

export const updateRoleClientListStatusApi = async(status, roleID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const objectData = {
            "haveAllClientsList": status,
        }

        const res = await axios.put(`${API_URL}/api/V2/operatorsRoles/updateOne/${id}/${iv}/${roleID}`, objectData, option);
        return res.data
    }
    catch (err) {
        return err
    }
}

export const getOneRoleApi = async(roleID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/operatorsRoles/updateOne/${id}/${iv}/${roleID}`, option);
        return res.data
    }
    catch (err) {
        return err
    }
}

export const getAllRoleApi = async() => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/operatorsRoles/getAll/${id}/${iv}`, option);
        return res.data
    }
    catch (err) {
        return err
    }
}

export const getAllRoleNameApi = async() => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/operatorsRoles/getAllRoleName/${id}/${iv}`, option);
        return res.data
    }
    catch (err) {
        return err
    }
}

export const deleteRoleApi = async(roleID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.delete(`${API_URL}/api/V2/operatorsRoles/deleteOne/${id}/${iv}/${roleID}`, option);
        return res.data
    }
    catch (err) {
        return err
    }
}
