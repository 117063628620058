import React, { useEffect, useState } from "react";
import { Button } from "@material-tailwind/react";
import { getOneReportDetailsApi } from "api/gestionStock";
import { pdfBcGeneratorApi, pdfBaGeneratorApi, pdfBlGeneratorApi, pdfBrGeneratorApi } from "api/pdf";

function ReportDetails({ typeOf, selectedReportId }) {
  const [reportDetails, setReportDetails] = useState({});
  const [reportCategories, setReportCategories] = useState([]);
  const [reportCities, setReportCities] = useState([]);

  useEffect(() => {
    getReportDetails(selectedReportId);
  }, [selectedReportId]);

  const handleDownloadReport = async (category, index) => {
    try {
      if(reportDetails.ModeOperation == "Avoir") {
        const response = await pdfBaGeneratorApi(selectedReportId, category, index);
        window.open(`${response}`, "_blank");
      }
      else {
        const response = await pdfBcGeneratorApi(selectedReportId, category, index);
        window.open(`${response}`, "_blank");
      }
    } catch (err) {}
  };

  const handleDownloadBlReport = async (city, index) => {
    try {
      const response = await pdfBlGeneratorApi(selectedReportId, city, index);
      window.open(`${response}`, "_blank");
    } catch (err) {}
  };

  const handleDownloadBRReport = async (city, index) => {
    try {
      const response = await pdfBrGeneratorApi(selectedReportId, city, index);
      window.open(`${response}`, "_blank");
    } catch (err) {}
  }

  const getReportDetails = async (reportID) => {
    try {
      // 65e5842f7479e2401758905a
      // 65df4962b5669f3d221e91ba
      console.log("reportID", reportID);

      const reportDetails = await getOneReportDetailsApi(reportID);
      setReportDetails(reportDetails);

      const reportCategories = Object.getOwnPropertyNames(
        reportDetails.Rapport
      );
      setReportCategories(reportCategories);

      if(typeOf === "BL" || typeOf === "BR") {
        const uniqueCities = [...new Set(reportDetails?.MagasinStock?.flat(1).map(obj => obj.city))];
        setReportCities(uniqueCities)
      }

    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {typeOf === "BI" && (
        <table className="mt-5 w-full">
          <thead>
            <tr>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Nom</p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Quantité</p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">
                  Réduction Commerciale
                </p>
              </th>
            </tr>
          </thead>

          {reportDetails?.Rapport && (
            <tbody>
              {Object.getOwnPropertyNames(reportDetails.Rapport).map((el) =>
                reportDetails.Rapport[el].produits.map((el2) => (
                  <tr>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-center text-sm font-bold text-navy-700">
                        {el2.name}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-center text-sm font-bold text-navy-700">
                        {el2.quantiteByUnity}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-center text-sm font-bold text-navy-700">
                        {el2.escompte === "" ? "" : `${el2.escompte}%`}
                      </p>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          )}

          {reportCategories.map((el, index) => (
            <div className="mt-2">
              <Button
                variant="gradient"
                color="blue"
                onClick={() => handleDownloadReport(el, index)}
                className=""
              >
                <span>Telecharger PDF : {el}</span>
              </Button>
            </div>
          ))}
        </table>
      )}

      {typeOf === "AI" && (
        <table className="mt-5 w-full">
          <thead>
            <tr>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Nom</p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Quantité</p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">
                  Réduction Commerciale
                </p>
              </th>
            </tr>
          </thead>

          {reportDetails?.Rapport && (
            <tbody>
              {Object.getOwnPropertyNames(reportDetails.Rapport).map((el) =>
                reportDetails.Rapport[el].produits.map((el2) => (
                  <tr>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-center text-sm font-bold text-navy-700">
                        {el2.name}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-center text-sm font-bold text-navy-700">
                        {el2.quantiteByUnity}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-center text-sm font-bold text-navy-700">
                        {el2.escompte === "" ? "" : `${el2.escompte}%`}
                      </p>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          )}

          {reportCategories.map((el) => (
            <div className="mt-2">
              <Button
                variant="gradient"
                color="blue"
                onClick={() => handleDownloadReport(el)}
                className=""
              >
                <span>Telecharger PDF : {el}</span>
              </Button>
            </div>
          ))}
        </table>
      )}

      {typeOf === "BL" && (
        <table className="mt-5 w-full">
          <thead>
            <tr>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">
                  Depot
                </p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">
                  Code produit
                </p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Produit</p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Quantité</p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Unité</p>
              </th>
            </tr>
          </thead>

          {reportDetails?.MagasinStock && (
            <tbody>
              {reportDetails.MagasinStock.flat(1).map((el) => (
                <tr>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-center text-sm font-bold text-navy-700">
                      {el.city}
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-center text-sm font-bold text-navy-700">
                      {el.Code}
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-center text-sm font-bold text-navy-700">
                      {el.productName}
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-center text-sm font-bold text-navy-700">
                      {el.quantity}
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-center text-sm font-bold text-navy-700">
                      {el.unity}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          )}

          {
            reportCities.map((el, index) => (
              <div className="mt-2">
                <Button
                  variant="gradient"
                  color="blue"
                  onClick={() => handleDownloadBlReport(el, index)}
                  className=""
                >
                  <span>Telecharger PDF : {el}</span>
                </Button>
              </div>
            ))
          }

        </table>
      )}

      {typeOf === "BR" && (
        <table className="mt-5 w-full">
          <thead>
            <tr>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">
                  Code produit
                </p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Produit</p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Quantité</p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Unité</p>
              </th>
            </tr>
          </thead>

          {reportDetails?.MagasinStock && (
            <tbody>
              {reportDetails.MagasinStock.flat(1).map((el) => (
                <tr>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-center text-sm font-bold text-navy-700">
                      {el.Code}
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-center text-sm font-bold text-navy-700">
                      {el.productName}
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-center text-sm font-bold text-navy-700">
                      {el.quantity}
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-center text-sm font-bold text-navy-700">
                      {el.unity}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          )}

          {
            reportCities.map((el, index) => (
              <div className="mt-2">
                <Button
                  variant="gradient"
                  color="blue"
                  onClick={() => handleDownloadBRReport(el, index)}
                  className=""
                >
                  <span>Telecharger PDF : {el}</span>
                </Button>
              </div>
            ))
          }

        </table>
      )}
    </div>
  );
}

export default ReportDetails;
