import React, { useState } from "react";
import avatar from "assets/img/avatars/avatar5.png";
import CardOperator from "components/card";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { Dialog, DialogHeader, DialogBody } from "@material-tailwind/react";

import EditeOp from "./EditeOp";
import API_URL from "config";
import { deleteOperatorApi } from "api/operator";

function OperatorCard({ operatorInfo, refresh, accessList }) {
  const [openEditeOp, setOpenEditeOp] = useState(false);

  const handleShowEditeOp = (OpID) => {
    try {
      setOpenEditeOp(!openEditeOp);
    } catch (err) {
      console.log(err);
    }
  };

  const initialData = () => {
    refresh()
  }

  const handleDeleteOp = (OpID) => {
    try {
      withReactContent(Swal)
        .fire({
          icon: "warning",
          title: "Supprimer ?",
          confirmButtonText: "Supprimer",
        })
        .then(async (result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            await deleteOperatorApi(OpID)
            refresh()
            Swal.fire("Supprimée", "", "success");
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <section className="">
      <Dialog size="xl" open={openEditeOp} handler={handleShowEditeOp}>
        <DialogHeader>
          <p className="w-full text-center"></p>
        </DialogHeader>
        <DialogBody>
          <EditeOp closePopup={handleShowEditeOp} opID={operatorInfo._id} initialData={initialData} />
        </DialogBody>
      </Dialog>

      <CardOperator extra={"items-center w-full h-full p-[16px] bg-cover"}>
        {/* Background and profile */}
        <div className="relative mt-1 flex h-10 w-full justify-center rounded-xl bg-cover">
          <div className="absolute -bottom-12 flex h-[100px] w-[100px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
            {operatorInfo?.imageProfile?.path ? (
              <img
                crossorigin="anonymous"
                className="h-full w-full rounded-full"
                src={`${API_URL}/${operatorInfo?.imageProfile?.path}`}
                alt=""
              />
            ) : (
              <img className="h-full w-full rounded-full" src={avatar} alt="" />
            )}
          </div>
        </div>

        {/* Name and position */}
        <div className="mt-16 flex flex-col items-center">
          <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            {operatorInfo?.Name}
          </h4>
          <p className="text-base font-normal text-gray-600">
            {operatorInfo?.userFromRole?.name}
          </p>
        </div>

        {/* Post followers */}
        <div className="mt-6 mb-3 flex gap-4 md:!gap-14">
        {
          accessList.Update && 
            <button
              href=""
              className="linear rounded-[10px] bg-orange-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-orange-400 active:bg-brand-700 dark:bg-orange-400 dark:hover:bg-orange-300 dark:active:opacity-90"
              onClick={() => handleShowEditeOp(operatorInfo._id)}
            >
              Modifier
            </button>
        }

        {
          accessList.Delete && 
            <button
              href=""
              className="linear rounded-[10px] bg-red-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-red-400 active:bg-brand-700 dark:bg-red-400 dark:hover:bg-red-300 dark:active:opacity-90"
              onClick={() => handleDeleteOp(operatorInfo._id)}
            >
              Supprimer
            </button>
        }

        </div>
      </CardOperator>
    </section>
  );
}

export default OperatorCard;
