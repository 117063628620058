import React, { useEffect, useState } from "react";
import Card from "components/card";
import { Switch, Button, Spinner, Tooltip } from "@material-tailwind/react";

import { IoEyeSharp } from "react-icons/io5";
import { HiPencilAlt } from "react-icons/hi";
import { FaTrash } from "react-icons/fa";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { Dialog, DialogHeader, DialogBody } from "@material-tailwind/react";

import ProductForm from "./Product_form";
import ProductInventaire from "./Product_inventaire";

import { ToastContainer, toast } from "react-toastify";
import { deleteOneProductApi, getAllProductsApi, updateProductStatusApi } from "api/products";
import { getAllProductsCategoriesApi } from "api/filtersData";

function ProductTable({ accessList }) {
  const [clienFormAction, setClienFormAction] = useState("show");

  const [showProductFormModale, setShowProductFormModale] = useState(false);
  const [showInvetaireModale, setShowInvetaireModale] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingXcel, setIsLoadingXcel] = useState(false);

  const [productsData, setProductsData] = useState([]);

  const [productsCategories, setProductsCategories] = useState([])

  const [inputFilterValue, setInputFilterValue] = useState({
    Name: "",
    Code: "",
    Categorie: "",
  });

  const [selectedProductID, setSelectedProductID] = useState("")

  useEffect(() => {
    getInitialData(
      inputFilterValue.Name,
      inputFilterValue.Code,
      inputFilterValue.Categorie
    );
    getAllProductsCategories()
  }, []);

  const getAllProductsCategories = async () => {
    try {
      const productsCategories = await getAllProductsCategoriesApi("")
      setProductsCategories(productsCategories)
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleProductChangeStatus = async (id, category) => {
    try {
      await updateProductStatusApi(id, category)

      toast.success("Modifiée", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      getInitialData(
        inputFilterValue.Name,
        inputFilterValue.Code,
        inputFilterValue.Categorie
      );
    }
    catch(err) {
      console.log(err)
    }
  }

  const getInitialData = async (Name, Code, Categorie) => {
    try {
      setIsLoading(true);
      const response = await getAllProductsApi(Name.trim(), Code.trim(), Categorie.trim());
      setProductsData(response);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const hadleShowToast = (type, message) => {
    if (type === "success") {
      setShowProductFormModale(!showProductFormModale);

      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      getInitialData(
        inputFilterValue.Name,
        inputFilterValue.Code,
        inputFilterValue.Categorie
      );
    }
    if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleFiltersInputChange = (e) => {
    const { name, value } = e.target;
    setInputFilterValue({ ...inputFilterValue, [name]: value });

    if(name == "Categorie")
      inputFilterValue.Categorie = value

    // getInitialData(
    //   inputFilterValue.Name,
    //   inputFilterValue.Code,
    //   inputFilterValue.Categorie
    // );
  };

  const handleFilterData = () => {
    getInitialData(
      inputFilterValue.Name,
      inputFilterValue.Code,
      inputFilterValue.Categorie
    );
  }

  const handleDeleteProduct = (id, categoryID) => {
    try {
      withReactContent(Swal)
        .fire({
          icon: "warning",
          title: "Supprimer ?",
          confirmButtonText: "Supprimer",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            console.log(categoryID)
            await deleteOneProductApi(id, categoryID)
            getInitialData(
              inputFilterValue.Name,
              inputFilterValue.Code,
              inputFilterValue.Categorie
            );

            Swal.fire("Supprimée", "", "success");
          }
        })
    } catch (err) {
      console.log(err);
    }
  };

  const handleProductFormPopup = (action, id) => {
    try {
      setShowProductFormModale(!showProductFormModale);
      setClienFormAction(action);

      setSelectedProductID(id)
    } catch (err) {
      console.log(err);
    }
  };

  const handleInventaireModal = () => {
    try {
      setShowInvetaireModale(!showInvetaireModale);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Card extra={"w-full pb-10 p-4 h-full"}>
      <ToastContainer />
      <Dialog
        size="xl"
        open={showProductFormModale}
        handler={handleProductFormPopup}
      >
        <DialogHeader>
          <p className="w-full text-center">
            {clienFormAction === "add"
              ? "Nouveau produit"
              : clienFormAction === "edite"
              ? "Modifier le produit"
              : "Voir les details de produit"}
          </p>
        </DialogHeader>
        <DialogBody>
          <ProductForm
            closePopup={handleProductFormPopup}
            clienFormAction={clienFormAction}
            showToast={hadleShowToast}
            productID={selectedProductID}
          />
        </DialogBody>
      </Dialog>
      <Dialog
        size="xl"
        open={showInvetaireModale}
        handler={handleInventaireModal}
      >
        <DialogHeader>
          <p className="w-full text-center">Liste des inventaires</p>
        </DialogHeader>
        <DialogBody className="h-[80vh] overflow-scroll">
          <ProductInventaire closePopup={handleInventaireModal} />
        </DialogBody>
      </Dialog>

      <header className="relative flex flex-col items-center justify-between md:flex-row">
        <div className="mb-5 text-xl font-bold text-navy-700 dark:text-white md:mb-0">
          Liste des produits
        </div>
        {
          accessList.Add &&
          <Button
          onClick={() => handleProductFormPopup("add", null)}
          className="linear mb-5 rounded-[10px] bg-gray-500 px-4 py-2 text-xs font-bold text-white transition duration-200 hover:bg-gray-400 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:mb-0"
          >
            Nouveau produit
          </Button>
        }

        {
          accessList.Inventaire &&
          <Button
          onClick={() => handleInventaireModal()}
          className="linear mb-5 rounded-[10px] bg-green-500 px-4 py-2 text-xs font-bold text-white transition duration-200 hover:bg-green-400 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:mb-0"
          >
            Inventaire
          </Button>
        }
      </header>

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Filtrer par catégorie :
          </small>
          <div>
            <select
              label="select Version"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

              name="Categorie"
              value={inputFilterValue.Categorie}
              onChange={handleFiltersInputChange}
            >
              <option value="">Tous</option>
              {
                productsCategories.map(el => (
                  <option value={el._id}>{el.Name}</option>
                ))
              }
            </select>
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Filtrer par produit :
          </small>
          <div>
            <input
              type="text"
              id=""
              placeholder="Tout"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="Name"
              value={inputFilterValue.Name}
              onChange={handleFiltersInputChange}
            />
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Filtrer par code produit :
          </small>
          <div>
            <input
              type="text"
              id=""
              placeholder="Tout"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="Code"
              value={inputFilterValue.Code}
              onChange={handleFiltersInputChange}
            />
          </div>
        </div>
        <div>
          <Button
            className="linear mb-5 rounded-[10px] bg-green-500 px-4 py-2 text-xs font-bold text-white transition duration-200 hover:bg-green-400 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:mb-0"
            onClick={handleFilterData}
          >
            Filtrer
          </Button>
        </div>
      </div>

      <div
        className="mt-8 overflow-x-scroll w-full"
        style={{ height: "300px", overflowY: "scroll" }}
      >
        <table className="w-full">
          <thead>
            <tr>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Catégories</p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Produits</p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Code des Produits</p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Prix HT</p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Tva %</p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Prix TTC</p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">
                  Qté en Stock
                </p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Actions</p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">
                  Activer/Désactiver
                </p>
              </th>
            </tr>
          </thead>

          {isLoading ? (
            <div className="flex w-full items-center justify-center">
              <Spinner className="h-12 w-12" />
            </div>
          ) : (
            <tbody>
              {productsData.map((el) => (
                <tr>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {el?.Categorie}
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {el?.Name}
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {el?.Code}
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {parseFloat(el?.Price).toFixed(3)}
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {el?.HaveTVA}
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {parseFloat(el?.PriceTTC).toFixed(3)}
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {parseFloat(el.Disponible).toFixed(3)}
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      <div className="flex">
                        {
                          accessList.Read &&
                          <Tooltip content="Material Tailwind" placement="top">
                            {
                              <IoEyeSharp
                                size={20}
                                color="gray"
                                className="mr-4 cursor-pointer"
                                onClick={() =>
                                  handleProductFormPopup("show", el._id)
                                }
                              />
                            }
                          </Tooltip>
                        }

                        {
                          accessList.Update &&
                          <HiPencilAlt
                            size={20}
                            color="#236571"
                            className="mr-4 cursor-pointer"
                            onClick={() =>
                              handleProductFormPopup("edite", el._id)
                            }
                          />
                        }

                        {
                          accessList.Delete &&
                          <FaTrash
                            size={20}
                            color="#cf503f"
                            className="mr-4 cursor-pointer"
                            onClick={() => handleDeleteProduct(el._id, el.CategorieID)}
                          />
                        }

                      </div>
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      <div className="flex justify-center">
                        {
                          accessList.Update &&
                          <Switch checked={el.isActive} onChange={() => handleProductChangeStatus(el._id, el.CategorieID)} />
                        }
                      </div>
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </Card>
  );
}

export default ProductTable;
