import Card from "components/card";
import React, { useEffect, useState } from "react";
import TimeLine from "./Time_line";
import {
  Dialog,
  DialogHeader,
  DialogBody,
} from "@material-tailwind/react";

import { getAllTcpsApi } from "api/filtersData";
import { getTimeLineDataApi } from "api/timeLine";

function TcpTable() {
  const [showTimelineModal, setShowTimelineModal] = useState(false)
  const [listOfTcp, setListOfTcp] = useState([])

  const [selectedTcp, setSelectedTcp] = useState("")
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())

  const [timeLineData, setTimeLineData] = useState([])

  useEffect(() => {
    getInitialData()
  }, [])

  const getInitialData = async () => {
    try {
      const response = await getAllTcpsApi()
      setListOfTcp(response)

      console.log(response)
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleShowTcpTimeLine = async (TCPID) => {
    try {
      setShowTimelineModal(!showTimelineModal)
      setSelectedTcp(TCPID)

      const filtredData = {
        opID: TCPID,
        getFullYear: selectedYear
      }
      const response = await getTimeLineDataApi(filtredData)
      setTimeLineData(response)
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {/* <Popup ref={popupRef} modal nested>
        {(close) => (
          <div className="modal rounded-[20px]">
            <div className="">
              <TimeLine />
              <TimeLine />
              <TimeLine />
              <TimeLine />
              <TimeLine />
              <TimeLine />
            </div>
          </div>
        )}
      </Popup> */}

      <Dialog
        size="xl"
        open={showTimelineModal}
        handler={handleShowTcpTimeLine}
      >
        <DialogHeader>
          <p className="w-full text-center">Vente (s)</p>
        </DialogHeader>
        <DialogBody className="h-[80vh] overflow-scroll">
          {
            timeLineData.map(el => (
              <TimeLine TimeLineData={el} />
            ))
          }
        </DialogBody>
      </Dialog>

      <Card extra={"w-full pb-10 p-4 h-full"}>
        <header className="relative flex items-center justify-between">
          <div className="text-xl font-bold text-navy-700 dark:text-white"></div>
        </header>
        <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
          <table className="w-full">
            <thead>
              <tr>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">TCP</p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">E-mail</p>
                </th>
              </tr>
            </thead>

            <tbody>
              {
                listOfTcp.map(el => (
                  <tr
                  onClick={() => handleShowTcpTimeLine(el._id)}
                  className="cursor-pointer hover:bg-gray-100 dark:hover:bg-navy-900"
                >
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {
                        el.Name
                      }
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {
                        el.Email
                      }
                    </p>
                  </td>
                </tr>
                ))
              }
            </tbody>
          </table>
        </div>
    </Card>
    </>
  );
}

export default TcpTable;
