import React, { useEffect, useState } from "react";
import Card from "components/card";
import { Button, Spinner, Switch } from "@material-tailwind/react";

import { IoEyeSharp } from "react-icons/io5";
import { HiPencilAlt } from "react-icons/hi";
import { FaTrash } from "react-icons/fa";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { ToastContainer, toast } from "react-toastify";

import { Dialog, DialogHeader, DialogBody } from "@material-tailwind/react";

import CategoryForm from "./Category_form";
import { deleteDepotApi } from "api/depot";
import { updateDepotCouterStatusApi } from "api/depot";
import { getAllCategoriesApi } from "api/category";
import { deleteCategoryApi } from "api/category";

function CategoryTable({ accessList }) {
  const [categoryFormAction, setCategoryFormAction] = useState("show");
  const [showCategoryForm, setCategoryForm] = useState(false);
  const [Categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [filerFormData, setFilerFormData] = useState({
    Name: ""
  });

  useEffect(() => {
    getTableData(filerFormData.Name);
  }, []);

  const getTableData = async (Name) => {
    try {
      const response = await getAllCategoriesApi(Name);
      setCategories(response);
      setIsLoading(true);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCategoryModal = (action, data) => {
    try {
      setCategoryForm(!showCategoryForm);
      setCategoryFormAction(action);

      setSelectedCategory(data);
    }
    catch (err) {
      console.log(err);
    }
  }

  const hadleShowToast = (type, message) => {
    if (type === "success") {
      setCategoryForm(!showCategoryForm);
      getTableData(filerFormData.Name);

      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleDeleteCategory = (id) => {
    try {
      withReactContent(Swal)
      .fire({
        icon: "warning",
        title: "Supprimer ?",
        confirmButtonText: "Supprimer",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteCategoryApi(id);
            getTableData(filerFormData.Name);
            Swal.fire("Supprimée", "", "success");
          }
          catch (err) {
            hadleShowToast("error", `${err?.response?.data?.error}`)
          }
        }
      });
    }
    catch (err) {
      // Swal.fire("", `${err?.response?.data?.error}`, "error");
      console.log(err)
    }
  }

  return (
    <Card extra={"w-full pb-10 p-4 h-full"}>
      <ToastContainer />
      <Dialog size="xl" open={showCategoryForm} handler={handleCategoryModal}>
        <DialogHeader>
          <p className="w-full text-center">
            {
              categoryFormAction === "add"
                ? "Nouvelle catégorie"
                : categoryFormAction === "edite"
                ? "Modifier le catégorie"
                : "Voir les details de catégorie"
            }
          </p>
        </DialogHeader>
        <DialogBody>
          <CategoryForm
            closePopup={handleCategoryModal}
            categoryFormAction={categoryFormAction}
            showToast={hadleShowToast}
            selectedCategory={selectedCategory}
          />
        </DialogBody>
      </Dialog>

      <header className="relative flex flex-col items-center justify-between md:flex-row">
        <div className="mb-5 text-xl font-bold text-navy-700 dark:text-white md:mb-0"></div>
        {accessList.Add && (
          <Button
            onClick={() => handleCategoryModal("add", null)}
            className="linear mb-5 rounded-[10px] bg-gray-500 px-4 py-2 text-xs font-bold text-white transition duration-200 hover:bg-gray-400 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:mb-0"
          >
            Nouvelle catégorie
          </Button>
        )}
      </header>

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2"></div>

      <div className="mt-8 overflow-x-scroll">
        {isLoading ? (
          <div className="flex w-full items-center justify-center">
            <Spinner className="h-12 w-12" />
          </div>
        ) : (
          <div
          className="mt-8 overflow-x-scroll"
          style={{ height: "50vh", overflowY: "scroll" }}
        >
          <table className="w-full">
            <thead>
              <tr>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">Nom</p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">Actions</p>
                </th>
              </tr>
            </thead>

            <tbody>
              {Categories.map((el) => (
                <tr>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {el.Name}
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      <div className="flex">
                        {accessList.Read && (
                          <IoEyeSharp
                            size={20}
                            color="gray"
                            className="cursor-pointer"
                            onClick={() => handleCategoryModal("show", el)}
                          />
                        )}
                        {accessList.Update && (
                          <HiPencilAlt
                            size={20}
                            color="#236571"
                            className="ml-4 mr-4 cursor-pointer"
                            onClick={() => handleCategoryModal("edite", el)}
                          />
                        )}
                        {accessList.Delete && (
                          <FaTrash
                            size={20}
                            color="#cf503f"
                            className="cursor-pointer"
                            onClick={() => handleDeleteCategory(el._id)}
                          />
                        )}
                      </div>
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        )}
      </div>
    </Card>
  )
}

export default CategoryTable
