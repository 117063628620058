import React, { useEffect, useState } from "react";
import { Stepper, Step, Typography, Button } from "@material-tailwind/react";
import { FaCheck } from "react-icons/fa";
import { MdDangerous } from "react-icons/md";

function TimeLine({TimeLineData}) {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    getInitialValue()
  }, [])

  const getInitialValue = async () => {
    try {
      if(TimeLineData.EtatDgeneral === "V") {
        setActiveStep(0)
      }
      if(TimeLineData.EtatCommercial === "V") {
        setActiveStep(1)
      }
      if(TimeLineData.EtatMagasinier === "V") {
        setActiveStep(2)
      }
      if(TimeLineData.EtatFacturation === "V") {
        setActiveStep(3)
      }
      if(TimeLineData.EtatMagasinierTwo === "V") {
        setActiveStep(4)
      }
      if(TimeLineData.EtatSuccess === "V") {
        setActiveStep(5)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleStepClick = (step) => {
    try {
      setActiveStep(step);
    } catch (err) {}
  };

  return (
    <section className="w-full py-4 px-4 mt-5">
      <Button variant="text" className="mb-2 flex items-center gap-2">
        <div>
          <p>{TimeLineData.InfoGenerale.NomSociete}</p> <p>{TimeLineData.ModeOperation}</p>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="h-5 w-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
          />
        </svg>
      </Button>

      {
        TimeLineData.ModeOperation === "Vente" ?
        <Stepper activeStep={activeStep}>
          <Step onClick={() => handleStepClick(0)}>
            {
              TimeLineData.EtatDgeneral === "V" ? <FaCheck />
              : TimeLineData.EtatDgeneral === "A" ? <MdDangerous />
              : <></>
            }
            
            <div className="absolute -bottom-[3rem] w-max text-center">
              <Typography
                variant="h6"
                className="text-sm"
                color={activeStep === 0 ? "blue-gray" : "gray"}
              >
                Validation <br /> DG
              </Typography>
            </div>
          </Step>
          <Step onClick={() => handleStepClick(1)}>
            {
              TimeLineData.EtatCommercial === "V" ? <FaCheck />
              : TimeLineData.EtatCommercial === "A" ? <MdDangerous />
              : <></>
            }
            <div className="absolute -bottom-[3rem] w-max text-center">
              <Typography
                variant="h6"
                className="text-sm"
                color={activeStep === 1 ? "blue-gray" : "gray"}
              >
                Validation <br /> Commercial
              </Typography>
            </div>
          </Step>
          <Step onClick={() => handleStepClick(2)}>
            {
              TimeLineData.EtatMagasinier === "V" ? <FaCheck />
              : TimeLineData.EtatMagasinier === "A" ? <MdDangerous />
              : <></>
            }
            <div className="absolute -bottom-[3rem] w-max text-center">
              <Typography
                variant="h6"
                className="text-sm"
                color={activeStep === 2 ? "blue-gray" : "gray"}
              >
                Validation <br /> magasinier
              </Typography>
            </div>
          </Step>
          <Step onClick={() => handleStepClick(3)}>
            {
              TimeLineData.EtatFacturation === "V" ? <FaCheck />
              : TimeLineData.EtatFacturation === "A" ? <MdDangerous />
              : <></>
            }
            <div className="absolute -bottom-[3rem] w-max text-center">
              <Typography
                variant="h6"
                className="text-sm"
                color={activeStep === 3 ? "blue-gray" : "gray"}
              >
                Validation <br /> Facturation
              </Typography>
            </div>
          </Step>
          <Step onClick={() => handleStepClick(4)}>
            {
              TimeLineData.EtatMagasinierTwo === "V" ? <FaCheck />
              : TimeLineData.EtatMagasinierTwo === "A" ? <MdDangerous />
              : <></>
            }
            <div className="absolute -bottom-[3rem] w-max text-center">
              <Typography
                variant="h6"
                className="text-sm"
                color={activeStep === 4 ? "blue-gray" : "gray"}
              >
                Validation <br /> magasinier
              </Typography>
            </div>
          </Step>
          <Step onClick={() => handleStepClick(5)}>
            {
              TimeLineData.EtatSuccess === "V" ? <FaCheck />
              : TimeLineData.EtatSuccess === "A" ? <MdDangerous />
              : <></>
            }
            <div className="absolute -bottom-[2rem] w-max text-center">
              <Typography
                variant="h6"
                className="text-sm"
                color={activeStep === 5 ? "blue-gray" : "gray"}
              >
                Livré
              </Typography>
            </div>
          </Step>
        </Stepper> :
        <Stepper activeStep={activeStep}>
          <Step onClick={() => handleStepClick(0)}>
            <FaCheck />
            <div className="absolute -bottom-[3rem] w-max text-center">
              <Typography
                variant="h6"
                className="text-sm"
                color={activeStep === 0 ? "blue-gray" : "gray"}
              >
                Validation <br /> DG
              </Typography>
            </div>
          </Step>
          <Step onClick={() => handleStepClick(3)}>
            <div className="absolute -bottom-[3rem] w-max text-center">
              <Typography
                variant="h6"
                className="text-sm"
                color={activeStep === 3 ? "blue-gray" : "gray"}
              >
                Validation <br /> Facturation
              </Typography>
            </div>
          </Step>
        </Stepper>
      }
    </section>
  );
}

export default TimeLine;
