import React, { useEffect, useState } from "react";
import Card from "components/card";

import {
  Dialog,
  DialogHeader,
  DialogBody,
  Button,
  Spinner,
} from "@material-tailwind/react";

import { ToastContainer, toast } from "react-toastify";

import { HiPencilAlt } from "react-icons/hi";
import { FaTrash } from "react-icons/fa";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import NewStockEnterForm from "./New_stock_enter_form";
import StockEnterEditeForm from "./Stock_enter_edite_form";
import { getAllStockEntryApi } from "api/stockEntry";
import { deleteOneStockEnterApi } from "api/stockEntry";
import { updateStockApi } from "api/stock";

function StockEnterTable({ accessList }) {
  const [showStockEnterModale, setShowStockEnterModale] = useState(false);
  const [showStockEnterEditeModale, setShowStockEnterEditeModale] =
    useState(false);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [inputFilterValue, setInputFilterValue] = useState({
    LotNumber: "",
    Produit: "",
    selectedFromDate: `${new Date().getFullYear()}-01-01`,
    selectedToDate: `${new Date().getFullYear()}-12-31`,
  });

  useEffect(() => {
    getInitialData(
      inputFilterValue.Produit,
      inputFilterValue.LotNumber,
      inputFilterValue.selectedFromDate,
      inputFilterValue.selectedToDate
    );
  }, []);

  const getInitialData = async (
    Produit,
    LotNumber,
    selectedFromDate,
    selectedToDate
  ) => {
    try {
      setIsLoading(true);

      const response = await getAllStockEntryApi(
        Produit,
        LotNumber,
        selectedFromDate,
        selectedToDate
      );
      setTableData(response);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFiltersInputChange = (e) => {
    const { name, value } = e.target;
    setInputFilterValue({ ...inputFilterValue, [name]: value });
    getInitialData(
      name == "Produit" ? value : inputFilterValue.Produit,
      name == "LotNumber" ? value : inputFilterValue.LotNumber,
      name == "selectedFromDate" ? value : inputFilterValue.selectedFromDate,
      name == "selectedToDate" ? value : inputFilterValue.selectedToDate
    );
  };

  const handleStockEnterModale = () => {
    try {
      setShowStockEnterModale(!showStockEnterModale);
    } catch (err) {
      console.log(err);
    }
  };

  const handleStockEnterEditeModale = () => {
    try {
      setShowStockEnterEditeModale(!showStockEnterEditeModale);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteStock = (data) => {
    try {
      withReactContent(Swal)
        .fire({
          icon: "warning",
          title: "Supprimer ?",
          confirmButtonText: "Supprimer",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              // update stock
              const selectedProductsList = [];
              selectedProductsList.push({
                Name: data.Produit,
                FromD: data.Depot,
                ToD: null,
                Quantity: parseFloat(data.Quantity),
                Type: "StockEnter",
                TypeOfEnter: "Exit",
                FromEntry: data._id,
              });

              await updateStockApi(selectedProductsList, "save");

              // delete stock entry
              await deleteOneStockEnterApi(data._id);

              getInitialData(
                inputFilterValue.Produit,
                inputFilterValue.LotNumber,
                inputFilterValue.selectedFromDate,
                inputFilterValue.selectedToDate
              );
              Swal.fire("Supprimée", "", "success");
            } catch (err) {
              Swal.fire(err.response.data.error, "", "error");
            }
          }
        });
    } catch (err) {
      Swal.fire(err, "", "error");
    }
  };

  const hadleShowToast = (type, message) => {
    if (type === "success") {
      setShowStockEnterModale(!showStockEnterModale);

      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      getInitialData(
        inputFilterValue.Produit,
        inputFilterValue.LotNumber,
        inputFilterValue.selectedFromDate,
        inputFilterValue.selectedToDate
      );
    }
    if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <Card extra={"w-full pb-10 p-4 h-full"}>
      <ToastContainer />
      <Dialog
        size="xl"
        open={showStockEnterModale}
        handler={handleStockEnterModale}
      >
        <DialogHeader>
          <p className="w-full text-center">Nouvelle entrée de stock</p>
        </DialogHeader>
        <DialogBody>
          <NewStockEnterForm
            closePopup={handleStockEnterModale}
            showToast={hadleShowToast}
          />
        </DialogBody>
      </Dialog>

      <Dialog
        size="xl"
        open={showStockEnterEditeModale}
        handler={handleStockEnterEditeModale}
      >
        <DialogHeader>
          <p className="w-full text-center">Modifier entrée de stock</p>
        </DialogHeader>
        <DialogBody>
          <StockEnterEditeForm closePopup={handleStockEnterEditeModale} />
        </DialogBody>
      </Dialog>

      <header className="relative flex flex-col items-center justify-between md:flex-row">
        <div className="mb-5 text-xl font-bold text-navy-700 dark:text-white md:mb-0"></div>
        {accessList.Add && (
          <Button
            onClick={() => handleStockEnterModale("")}
            className="linear mb-5 rounded-[10px] bg-green-500 px-4 py-2 text-xs font-bold text-white transition duration-200 hover:bg-green-400 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:mb-0"
          >
            Ajouter une entrée de stock
          </Button>
        )}
      </header>
      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Numéro de lot :
          </small>
          <div>
            <input
              type="text"
              id=""
              placeholder=""
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="LotNumber"
              value={inputFilterValue.LotNumber}
              onChange={handleFiltersInputChange}
            />
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Produits :
          </small>
          <div>
            <input
              type="text"
              id=""
              placeholder=""
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="Produit"
              value={inputFilterValue.Produit}
              onChange={handleFiltersInputChange}
            />
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Période :
          </small>
          <div>
            <input
              type="date"
              id=""
              placeholder=""
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="selectedFromDate"
              value={inputFilterValue.selectedFromDate}
              onChange={handleFiltersInputChange}
            />
            <input
              type="date"
              id=""
              placeholder=""
              className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="selectedToDate"
              value={inputFilterValue.selectedToDate}
              onChange={handleFiltersInputChange}
            />
          </div>
        </div>
      </div>

      <div className="mt-8 overflow-x-scroll">
        <div
          className="mt-8 overflow-x-scroll"
          style={{ height: "50vh", overflowY: "scroll" }}
        >
          <table className="w-full">
            <thead>
              <tr>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">Date</p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">
                    Catégorie
                  </p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">Produit</p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">
                    Emballage
                  </p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">
                    Quantité
                  </p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">Dépôt</p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">
                    Numero de lot
                  </p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">
                    Date d'éxpiration
                  </p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">Actions</p>
                </th>
              </tr>
            </thead>

            {isLoading ? (
              <tbody>
                <tr>
                  <div className="flex w-full items-center justify-center">
                    <Spinner className="h-12 w-12" />
                  </div>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {tableData.map((el) => (
                  <tr>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.SelectedDate?.split("T")[0]
                          .split("-")
                          .reverse()
                          .join("-")}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.Category}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.Produit}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.ProductDetails[0]
                          ? el.ProductDetails[0].Packaging +
                            " " +
                            el.ProductDetails[0].Unity
                          : ""}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {parseFloat(el.Quantity)
                          ? parseFloat(el.Quantity).toFixed(2)
                          : ""}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.Depot}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.LotNumber}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.ExperationDate?.split("T")[0]
                          .split("-")
                          .reverse()
                          .join("-")}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        <div className="flex">
                          {accessList.Delete && (
                            <FaTrash
                              size={20}
                              color="#cf503f"
                              className="cursor-pointer"
                              onClick={() => handleDeleteStock(el)}
                            />
                          )}
                        </div>
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </Card>
  );
}

export default StockEnterTable;

{
  /* <HiPencilAlt
size={20}
color="#236571"
className="ml-4 mr-4 cursor-pointer"
onClick={() => handleStockEnterEditeModale()}
/> */
}
