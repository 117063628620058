import React, { useEffect, useState } from "react";
import { getOneReportDetailsApi } from "api/gestionStock";

import EditeRecouvryDate from "./EditeRecouvryDate";
import RecouvryDates from "./RecouvryDates";

import { FaPencilAlt } from "react-icons/fa";
import { BiDetail } from "react-icons/bi";

import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  Spinner,
  IconButton,
} from "@material-tailwind/react";
import { pdfRecouvryGeneratorApi } from "api/pdf";

function ReportDetails({ typeOf, selectedReportId, accessList }) {
  const [reportDetails, setReportDetails] = useState({});
  const [reportCategories, setReportCategories] = useState([]);

  const [openEditeRecouvryDate, setOpenEditeRecouvryDate] = useState(false);
  const [openRecouvryDates, setOpenRecouvryDates] = useState(false);

  const [selectedRecovryReportId, setSelectedRecovryReportId] = useState(null);
  const [selectedRecovryInfo, setSelectedRecovryInfo] = useState("");
  const [selectedRecovryType, setSelectedRecovryType] = useState("")

  useEffect(() => {
    getReportDetails(selectedReportId);
  }, [selectedReportId]);

  const getReportDetails = async (reportID) => {
    try {
      // 65dfa5c2043d7e5efef077f1
      console.log("reportID", reportID);

      const reportDetails = await getOneReportDetailsApi(reportID);
      setReportDetails(reportDetails);

      const reportSelectedCategories = [];

      Object.getOwnPropertyNames(reportDetails.Rapport.recouvrement).map(
        (el) => {
          const report = reportDetails.Rapport.recouvrement[el];
          if (el === "Cheque_facture" && report.montant !== "")
            reportSelectedCategories.push("Cheque_facture");

          if (el === "Cheque" && report.ChequeInfo.length > 0)
            reportSelectedCategories.push("Cheque");

          if (el === "Effet" && report.EffetInfo.length > 0)
            reportSelectedCategories.push("Effet");

          if (el === "Espece" && report.montant !== "")
            reportSelectedCategories.push("Espece");

          if (el === "Virement" && report.montant !== "")
            reportSelectedCategories.push("Virement");
        }
      );

      setReportCategories(reportSelectedCategories);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenEditeRecouvryDate = (id, info, type) => {
    setSelectedRecovryReportId(id);
    setOpenEditeRecouvryDate(!openEditeRecouvryDate);
    setSelectedRecovryInfo(info);
    setSelectedRecovryType(type)
  };

  const handleOpenRecouvryDates = (id, info, type) => {
    setSelectedRecovryReportId(id);
    setOpenRecouvryDates(!openRecouvryDates);
    setSelectedRecovryInfo(info);
    setSelectedRecovryType(type)
  };

  const handleDownloadReport = async (id, category) => {
    try {
      const response = await pdfRecouvryGeneratorApi(id, category)
      window.open(`${response}`, '_blank')
    } catch (err) {}
  };

  return (
    <div
      className="mt-8 overflow-x-scroll xl:overflow-x-hidden"
      style={{
        overflow: "scroll",
        height: "80vh",
      }}
    >
      <Dialog
        size="xl"
        open={openEditeRecouvryDate}
        handler={handleOpenEditeRecouvryDate}
      >
        <DialogHeader>
          <p className="w-full text-center">Modifier la date de recouvrement</p>
        </DialogHeader>
        <DialogBody>
          <EditeRecouvryDate
            typeOf=""
            selectedReportId={selectedRecovryReportId}
            selectedRecovryInfo={selectedRecovryInfo}
            selectedRecovryType={selectedRecovryType}
          />
        </DialogBody>
      </Dialog>

      <Dialog
        size="xl"
        open={openRecouvryDates}
        handler={handleOpenRecouvryDates}
      >
        <DialogHeader>
          <p className="w-full text-center"></p>
        </DialogHeader>
        <DialogBody>
          <RecouvryDates
            typeOf=""
            selectedReportId={selectedRecovryReportId}
            selectedRecovryInfo={selectedRecovryInfo}
          />
        </DialogBody>
      </Dialog>

      {reportCategories.includes("Cheque_facture") && (
        <table className="mt-5 w-full">
          <thead>
            <tr>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-left text-xs tracking-wide text-gray-600">
                  Banque
                </p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-left text-xs tracking-wide text-gray-600">
                  N° Chèque
                </p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-left text-xs tracking-wide text-gray-600">
                  Montant
                </p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-left text-xs tracking-wide text-gray-600">
                  Date
                </p>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <p className="text-left text-sm font-bold text-navy-700">
                  BANK OF AFRICA
                </p>
              </td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <p className="text-left text-sm font-bold text-navy-700">
                  4682150
                </p>
              </td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <p className="text-left text-sm font-bold text-navy-700">
                  34800
                </p>
              </td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <div>
                  <p className="text-left text-sm font-bold text-navy-700">
                    10-05-2024
                  </p>
                </div>
              </td>
            </tr>

            <tr>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]"></td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]"></td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <p className="text-left text-sm font-bold text-navy-700">
                  69600
                </p>
              </td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]"></td>
            </tr>
          </tbody>

          <div className="">
            <Button
              variant="gradient"
              color="blue"
              onClick={() => handleDownloadReport()}
              className=""
            >
              <span>Telecharger PDF Cheque_facture</span>
            </Button>
          </div>
        </table>
      )}
      {reportCategories.includes("Cheque") && (
        <table className="mt-5 w-full">
          <thead>
            <tr>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-left text-xs tracking-wide text-gray-600">
                  Banque
                </p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-left text-xs tracking-wide text-gray-600">
                  N° Chèque
                </p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-left text-xs tracking-wide text-gray-600">
                  Montant
                </p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-left text-xs tracking-wide text-gray-600">
                  Date
                </p>
              </th>
            </tr>
          </thead>

          <tbody>
            {reportDetails.Rapport.recouvrement.Cheque.ChequeInfo.map((el) => (
              <tr>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-left text-sm font-bold text-navy-700">
                    {el.Bank}
                  </p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-left text-sm font-bold text-navy-700">
                    {el.Ncheque}
                  </p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-left text-sm font-bold text-navy-700">
                    {el.Montant}
                  </p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <div>
                    <p className="text-left text-sm font-bold text-navy-700">
                      {el.DateEcheance}
                      <div style={{ display: "flex" }}>
                        {
                          accessList.Update &&
                          <FaPencilAlt
                            size={20}
                            style={{ cursor: "pointer", marginRight: "20px" }}
                            onClick={() =>
                              handleOpenEditeRecouvryDate(
                                selectedReportId,
                                el,
                                "Cheque"
                              )
                            }
                          />
                        }
                        {
                          accessList.Read &&
                          <BiDetail
                            size={20}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleOpenRecouvryDates(
                                selectedReportId,
                                el,
                                "Cheque"
                              )
                            }
                          />
                        }
                      </div>
                    </p>
                  </div>
                </td>
              </tr>
            ))}

            <tr>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <p className="text-left text-sm font-bold text-navy-700"></p>
              </td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <p className="text-left text-sm font-bold text-navy-700"></p>
              </td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <hr />
                <p className="text-left text-sm font-bold text-navy-700">
                  {reportDetails.Rapport.recouvrement.Cheque.ChequeInfo.reduce(
                    (acc, obj) => acc + parseFloat(obj.Montant || 0),
                    0
                  ).toFixed(2)}
                </p>
              </td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <div>
                  <p className="text-left text-sm font-bold text-navy-700"></p>
                </div>
              </td>
            </tr>
          </tbody>

          <div className="">
            <Button
              variant="gradient"
              color="blue"
              onClick={() => handleDownloadReport(reportDetails._id, "Cheque")}
              className=""
            >
              <span>Telecharger PDF Cheque</span>
            </Button>
          </div>
        </table>
      )}
      {reportCategories.includes("Effet") && (
        <table className="mt-5 w-full">
          <thead>
            <tr>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-left text-xs tracking-wide text-gray-600">
                  Banque
                </p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-left text-xs tracking-wide text-gray-600">
                  N° Effet
                </p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-left text-xs tracking-wide text-gray-600">
                  Montant
                </p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-left text-xs tracking-wide text-gray-600">
                  Date
                </p>
              </th>
            </tr>
          </thead>

          <tbody>
            {reportDetails.Rapport.recouvrement.Effet.EffetInfo.map((el) => (
              <tr>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-left text-sm font-bold text-navy-700">
                    {el.Bank}
                  </p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-left text-sm font-bold text-navy-700">
                    {el.NEffet}
                  </p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-left text-sm font-bold text-navy-700">
                    {el.Montant}
                  </p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <div>
                    <p className="text-left text-sm font-bold text-navy-700">
                      {el.DateEcheance}
                      <div style={{ display: "flex" }}>
                        {
                          accessList.Update &&
                          <FaPencilAlt
                            size={20}
                            style={{ cursor: "pointer", marginRight: "20px" }}
                            onClick={() =>
                              handleOpenEditeRecouvryDate(
                                selectedReportId,
                                el,
                                "Effet"
                              )
                            }
                          />
                        }
                        {
                          accessList.Read &&
                          <BiDetail
                            size={20}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleOpenRecouvryDates(
                                selectedReportId,
                                el,
                                "Effet"
                              )
                            }
                          />
                        }
                      </div>
                    </p>
                  </div>
                </td>
              </tr>
            ))}

            <tr>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <p className="text-left text-sm font-bold text-navy-700"></p>
              </td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <p className="text-left text-sm font-bold text-navy-700"></p>
              </td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <hr />
                <p className="text-left text-sm font-bold text-navy-700">
                  {reportDetails.Rapport.recouvrement.Effet.EffetInfo.reduce(
                    (acc, obj) => acc + parseFloat(obj.Montant || 0),
                    0
                  ).toFixed(2)}
                </p>
              </td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <div>
                  <p className="text-left text-sm font-bold text-navy-700"></p>
                </div>
              </td>
            </tr>
          </tbody>

          <div className="">
            <Button
              variant="gradient"
              color="blue"
              onClick={() => handleDownloadReport(reportDetails._id, "Effet")}
              className=""
            >
              <span>Telecharger PDF Effet</span>
            </Button>
          </div>
        </table>
      )}
      {reportCategories.includes("Espece") && (
        <table className="mt-5 w-full">
          <thead>
            <tr>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-left text-xs tracking-wide text-gray-600">
                  Banque
                </p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-left text-xs tracking-wide text-gray-600">
                  N° Chèque
                </p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-left text-xs tracking-wide text-gray-600">
                  Montant
                </p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-left text-xs tracking-wide text-gray-600">
                  Date
                </p>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <p className="text-left text-sm font-bold text-navy-700">
                  
                </p>
              </td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <p className="text-left text-sm font-bold text-navy-700">
                  
                </p>
              </td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <p className="text-left text-sm font-bold text-navy-700">
                  {reportDetails.Rapport.recouvrement.Espece.montant}
                </p>
              </td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <div>
                  <p className="text-left text-sm font-bold text-navy-700">
                    
                  </p>
                </div>
              </td>
            </tr>

            {/* <tr>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]"></td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]"></td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <p className="text-left text-sm font-bold text-navy-700">
                {reportDetails.Rapport.recouvrement.Espece.montant}
                </p>
              </td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]"></td>
            </tr> */}
          </tbody>

          <div className="">
            <Button
              variant="gradient"
              color="blue"
              onClick={() => handleDownloadReport(reportDetails._id, "Espece")}
              className=""
            >
              <span>Telecharger PDF Espece</span>
            </Button>
          </div>
        </table>
      )}
      {reportCategories.includes("Virement") && (
        <table className="mt-5 w-full">
          <thead>
            <tr>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-left text-xs tracking-wide text-gray-600">
                  Banque
                </p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-left text-xs tracking-wide text-gray-600">
                  N° Chèque
                </p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-left text-xs tracking-wide text-gray-600">
                  Montant
                </p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-left text-xs tracking-wide text-gray-600">
                  Date
                </p>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <p className="text-left text-sm font-bold text-navy-700">
                  
                </p>
              </td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <p className="text-left text-sm font-bold text-navy-700">
                  
                </p>
              </td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <p className="text-left text-sm font-bold text-navy-700">
                  {reportDetails.Rapport.recouvrement.Virement.montant}
                </p>
              </td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <div>
                  <p className="text-left text-sm font-bold text-navy-700">
                    
                  </p>
                </div>
              </td>
            </tr>

            {/* <tr>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]"></td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]"></td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <p className="text-left text-sm font-bold text-navy-700">
                  {reportDetails.Rapport.recouvrement.Virement.montant}
                </p>
              </td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]"></td>
            </tr> */}
          </tbody>

          <div className="">
            <Button
              variant="gradient"
              color="blue"
              onClick={() => handleDownloadReport(reportDetails._id, "Virement")}
              className=""
            >
              <span>Telecharger PDF Virement</span>
            </Button>
          </div>
        </table>
      )}
    </div>
  );
}

export default ReportDetails;
