import React from 'react'
import ListOfRoles from './components/List_of_roles'

function RoleManagement() {
  return (
    <div>
      <ListOfRoles />
    </div>
  )
}

export default RoleManagement
