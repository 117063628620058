import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  Spinner,
  IconButton,
} from "@material-tailwind/react";

import {
  IoMdArrowDropleftCircle,
  IoMdArrowDroprightCircle,
} from "react-icons/io";

import RecouvryReportDetails from "../RecouvryReportDetails";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getAllReportsListApi, updateFCreatedTApi, updateReportDataApi } from "api/gestionStock";

function RecouvryInvoces({ selected, filterInputs, accessList }) {
  const [openReportDetails, setOpenReportDetails] = useState(false);

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [reportsList, setReportsList] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  const [active, setActive] = useState(1);
  const [currentPagination, setCurrentPagination] = useState(1);

  const [numberOfPages, setNumberOfPages] = useState(0);
  const [numberOfPaginations, setNumberOfPaginations] = useState(0);
  const [numberOfReports, setNumberOfReports] = useState(0);

  const [selectedReportId, setSelectedReportId] = useState({})

  useEffect(() => {
    getInitialData(1, currentYear, "Recouvrement", filterInputs.clientName, "", "", filterInputs.etatMagasin, "", "");
  }, [selected, filterInputs, accessList]);

  const getInitialData = async (
    page,
    currentYear,
    MP,
    clientName,
    etatMagasin,
    EtatBonLivraison,
    EtatFacturation,
    EtatMagasinierTwo,
    EtatValidationB
  ) => {
    try {
      setIsLoading(true);
      const reportsData = await getAllReportsListApi(
        page,
        currentYear,
        MP,
        clientName,
        etatMagasin,
        EtatBonLivraison,
        EtatFacturation,
        EtatMagasinierTwo,
        EtatValidationB
      );
      setReportsList(reportsData);

      setNumberOfPages(reportsData.pagination.numberOfPages);
      setNumberOfPaginations(reportsData.pagination.numberOfPaginations);
      setNumberOfReports(reportsData.pagination.numberOfClients);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenReportDetails = (id) => {
    setSelectedReportId(id)
    setOpenReportDetails(!openReportDetails);
  };

  const handleValidateReport = (reportID) => {
    try {
      if(accessList.Update) {
        withReactContent(Swal)
        .fire({
          icon: "warning",
          title: "Voulez-vous vraiment valider cette opération ?",
          confirmButtonText: "Valider",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            setIsLoading(true);
            const updatedObject = {
              EtatFacturation: "V",
            };
            await updateReportDataApi(reportID, updatedObject)
            await updateFCreatedTApi(reportID)
            getInitialData(active, currentYear, "Recouvrement", filterInputs.clientName, "", "", filterInputs.etatMagasin, "", "")
            Swal.fire("Validée", "", "success");
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
    finally {
      setIsLoading(false);
    }
  }

  const handleCancelReport = (reportID) => {
    try {
      if(accessList.Update) {
        withReactContent(Swal)
        .fire({
          icon: "warning",
          title: "Voulez-vous vraiment annuler cette opération ?",
          confirmButtonText: "Annuler",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            setIsLoading(true);
            const updatedObject = {
              EtatFacturation: "A",
            };
            await updateReportDataApi(reportID, updatedObject)
            await updateFCreatedTApi(reportID)
            getInitialData(active, currentYear, "Recouvrement", filterInputs.clientName, "", "", filterInputs.etatMagasin, "", "")
            Swal.fire("Annulée", "", "success");
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
    finally {
      setIsLoading(false);
    }
  }

  const getItemProps = (index) => ({
    variant: active === index ? "filled" : "text",
    color: "gray",
    onClick: () => {
      getInitialData(index, currentYear, "Recouvrement", filterInputs.clientName, "", "", filterInputs.etatMagasin, "", "");
      setActive(index);
    },
  });

  const next = () => {
    if (currentPagination === numberOfPaginations) return;
    setCurrentPagination(currentPagination + 1);
  };

  const prev = () => {
    if (currentPagination === 1) return;
    setCurrentPagination(currentPagination - 1);
  };

  return (
    <>
    <div style={{ height: "40vh", overflowY: "scroll" }}>
      <Dialog
        size="xl"
        open={openReportDetails}
        handler={handleOpenReportDetails}
      >
        <DialogHeader>
          <p className="w-full text-center">Recouvrement</p>
        </DialogHeader>
        <DialogBody>
          <RecouvryReportDetails typeOf="RECOVRY" selectedReportId={selectedReportId} accessList={accessList} />
        </DialogBody>
      </Dialog>

      {isLoading ? (
            <div className="flex w-full items-center justify-center">
              <Spinner className="h-12 w-12" />
            </div>
          ) : (
            reportsList?.data.map((el) => (
              <Card className="mt-6 w-full cursor-pointer dark:bg-indigo-900">
                <CardBody onClick={() => handleOpenReportDetails(el._id)}>
                  <Typography
                    variant="h5"
                    color="blue-gray"
                    className="mb-2 dark:text-white"
                  >
                    {el.InfoGenerale.NomSociete}
                    {el.RBCisOpened ? "-" + el.RBC : ""}
                  </Typography>
                  {
                    el?.Rapport?.recouvrement ?
                    <Typography style={{ fontWeight: 'bold' }}>
                    {Object.getOwnPropertyNames(el?.Rapport?.recouvrement)?.map(el2 => (
                      <>
                        {
                          el2 == "Cheque" ?
                          el?.Rapport?.recouvrement[el2]?.ChequeInfo?.length !== 0 ? `${el2} ` : null
                          : el2 == "Effet" ?
                          el?.Rapport?.recouvrement[el2]?.EffetInfo?.length !== 0 ? `${el2} ` : null
                          : el?.Rapport?.recouvrement[el2]?.montant !== "" ? `${el2} ` : null
                        }
                      </>
                    ))}
                    </Typography> : <></>
                  }
                  <Typography>
                    {el?.ModeOperation} / {el?.CommercialName}
                  </Typography>
                  <Typography>{el?.InfoGenerale?.Adress}</Typography>
                  <Typography>
                    {el?.CurrentDate} - {el?.CurrentTime}
                  </Typography>
                </CardBody>
                {el?.EtatFacturation == "N" ? (
                  <CardFooter className="flex pt-0">
                    <Button
                      variant="gradient"
                      color="#236571"
                      onClick={() => handleValidateReport(el?._id)}
                      className="mr-1"
                      disabled={!accessList?.Update}
                    >
                      <span>Valider</span>
                    </Button>
                    <Button
                      variant="text"
                      color="#cf503f"
                      onClick={() => handleCancelReport(el?._id)}
                      className="mr-1"
                      disabled={!accessList?.Update}
                    >
                      <span>Annuler</span>
                    </Button>
                  </CardFooter>
                ) : el.EtatFacturation == "V" ? (
                  <CardFooter className="flex pt-0">
                    <Button
                      variant="gradient"
                      color="#236571"
                      className="mr-1"
                      disabled
                    >
                      <span>Validée</span>
                    </Button>
                  </CardFooter>
                ) : (
                  <CardFooter className="flex pt-0">
                    <Button
                      variant="gradient"
                      color="#236571"
                      className="mr-1"
                      disabled
                    >
                      <span>Annulée</span>
                    </Button>
                  </CardFooter>
                )}
              </Card>
            ))
          )}
    </div>

    {!isLoading && numberOfReports != 0 && (
          <div className="mt-4 flex items-center gap-4">
            <Button
              variant="text"
              className="flex items-center gap-2"
              onClick={prev}
              disabled={currentPagination === 1}
            >
              <IoMdArrowDropleftCircle size={30} />
            </Button>
            <div className="flex items-center gap-2">
              {currentPagination !== numberOfPaginations
                ? // paginations row
                  Array.from({
                    length: numberOfPages > 5 ? 5 : numberOfPages,
                  }).map((el, index) => {
                    const pageNumber = index + 1 + (currentPagination - 1) * 5;
                    return (
                      <IconButton {...getItemProps(pageNumber)}>
                        {pageNumber}
                      </IconButton>
                    );
                  })
                : // last pagination row
                  Array.from({ length: numberOfPages % 5 }).map((el, index) => {
                    const pageNumber = index + 1 + (currentPagination - 1) * 5;
                    return (
                      <IconButton {...getItemProps(pageNumber)}>
                        {pageNumber}
                      </IconButton>
                    );
                  })}
            </div>
            <Button
              variant="text"
              className="flex items-center gap-2"
              onClick={next}
              disabled={
                currentPagination === numberOfPaginations ||
                numberOfPaginations === 0
              }
            >
              <IoMdArrowDroprightCircle size={30} />
            </Button>
          </div>
      )}
    </>
  );
}

export default RecouvryInvoces;
