import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import OperatorCard from "./components/Operator_card";
import { IoPersonAdd } from "react-icons/io5";

import {
  Dialog,
  DialogHeader,
  DialogBody,
  Button,
} from "@material-tailwind/react";

import NewOp from "./components/NewOp";
import NewRole from "./components/NewRole";

import { ToastContainer, toast } from "react-toastify";
import { getAllOperatorsApi } from "api/operator";
import { Spinner } from "@material-tailwind/react";

import { getCookie } from "utils/cookies";
import { getOneOperatorApi } from "api/operator";

import { socket } from "socket";

function OperatorManagement() {
  const { userID } = useParams();

  const [openNewOp, setOpenNewOp] = useState(false);
  const [openNewRole, setOpenNewRole] = useState(false);

  const [listOfOperators, setListOfOperators] = useState([]);

  const [accessList, setAccessList] = useState({})
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    socket.on('reloadOperatorSeassion', (messageData) => {
      getAllOperators()
    });

    getAllOperators();
  }, []);

  const getAllOperators = async () => {
    try {
      setIsLoading(true)
      const response = await getAllOperatorsApi();
      setListOfOperators(response);

      const id = getCookie("3F2Mb2pJ1YRro5Z4")
      const adminRole = getCookie("lv9u765qzk1gr4fn1e")

      if(adminRole === "FALSE") {
        const response = await getOneOperatorApi(id)
        const accessList = response.userFromRole.access

        const selectedAccessDetails = accessList.find(el => el.value._id == userID)

        const accessObject = {
          Add: false,
          Read: false,
          Update: false,
          Delete: false
        }

        selectedAccessDetails.sectionItems.map(el => {
          if(el.value == "Add" && el.checked) {
            accessObject.Add = true
          }
          if(el.value == "Read" && el.checked) {
            accessObject.Read = true
          }
          if(el.value == "Update" && el.checked) {
            accessObject.Update = true
          }
          if(el.value == "Delete" && el.checked) {
            accessObject.Delete = true
          }
        })

        setAccessList({
          Add: accessObject.Add,
          Read: accessObject.Read,
          Update: accessObject.Update,
          Delete: accessObject.Delete
        })
      }
      else if(adminRole === "TRUE") {
        setAccessList({
          Add: true,
          Read: true,
          Update: true,
          Delete: true
        })
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false)
    }
  };

  const handleAddNewOpModal = (params) => {
    try {
      setOpenNewOp(!openNewOp);
      if (params === "Reload") {
        toast.success("Opérator a été ajoutée", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        getAllOperators()
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenNewRoleModal = (params) => {
    try {
      setOpenNewRole(!openNewRole);
      if (params === "Reload") {
        toast.success("Role a été ajoutée", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <section className="">
      <ToastContainer />
      <Dialog size="xl" open={openNewOp} handler={handleAddNewOpModal}>
        <DialogHeader>
          <p className="w-full text-center"></p>
        </DialogHeader>
        <DialogBody>
          <NewOp closePopup={handleAddNewOpModal} />
        </DialogBody>
      </Dialog>

      <Dialog size="xxl" open={openNewRole} handler={handleOpenNewRoleModal}>
        <DialogHeader>
          <p className="w-full text-center">Ajouter un nouveau rôle</p>
        </DialogHeader>
        <DialogBody>
          <NewRole closePopup={handleOpenNewRoleModal} />
        </DialogBody>
      </Dialog>

      <div className="mt-8 w-full">
        {/* <Button
          variant="gradient"
          color="#236571"
          onClick={() => handleOpenNewRoleModal()}
          className="ml-5"
        >
          <span>Ajouter un nouveau rôle</span>
        </Button> */}
      </div>

      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-3">
        {
          isLoading ? 
          <div className="flex items-center justify-center">
          <Spinner className="h-12 w-12" />
          </div> :
          listOfOperators.map((el) => <OperatorCard operatorInfo={el} refresh={getAllOperators} accessList={accessList} />)
        }

        {
          accessList.Add ?
          <div className="flex items-center justify-center rounded-[20px] dark:!bg-navy-800">
            <IoPersonAdd
              size={150}
              className="cursor-pointer dark:text-white"
              onClick={() => handleAddNewOpModal()}
            />
          </div> : <></>
        }

      </div>
    </section>
  );
}

export default OperatorManagement;
