import { Button, Switch } from "@material-tailwind/react";
import React from "react";

function OperationForm({ closePopup, operationID }) {
  const handleCancelOp = () => {
    try {
      closePopup("Close");
    } catch (err) {}
  };

  const handleEditeOp = () => {
    try {
      
    } catch (err) {}
  };

  return (
    <form>
      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Nom de la société
          </label>
          <input
            type="text"
            id=""
            placeholder="Nom de la société"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Téléphone
          </label>
          <input
            type="text"
            id=""
            placeholder="Téléphone"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            ICE
          </label>
          <input
            type="text"
            id=""
            placeholder="ICE"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
          />
        </div>
      </div>

      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Adress
          </label>
          <input
            type="text"
            id=""
            placeholder="Adress"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            E-mail
          </label>
          <input
            type="text"
            id=""
            placeholder="E-mail"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Code client
          </label>
          <input
            type="text"
            id=""
            placeholder="Code client"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
          />
        </div>
      </div>

      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            N° BL sur la facture
          </label>
          <input
            type="text"
            id=""
            placeholder="N° BL sur la facture"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
          />
        </div>
        <div className="flex flex-col mt-5">
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Activer la modification de N° BL sur la facture
          </label>
          <Switch
          defaultChecked
          />
        </div>
      </div>

      <div className="mt-5">
          <Button
            variant="gradient"
            color="#236571"
            onClick={() => handleEditeOp()}
            className="mr-1"
          >
            <span>Modifier</span>
          </Button>
          <Button
            variant="text"
            color="#cf503f"
            onClick={() => handleCancelOp()}
            className="mr-1"
          >
            <span>Annuler</span>
          </Button>
        </div>
    </form>
  );
}

export default OperationForm;
