import React from 'react'
import TcpTable from './components/Tcp_table'

function Chronologie() {
  return (
    <section>
        <TcpTable />
    </section>
  )
}

export default Chronologie
