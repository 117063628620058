import React, { useEffect, useState } from "react";
import { Button, Spinner } from "@material-tailwind/react";
import { FaPlusCircle, FaTrash } from "react-icons/fa";
import { getAllProductsApi } from "api/products";

// combox
import { Combobox } from "@headlessui/react";
import { updateStockApi } from "api/stock";
import { updateStockAvoirApi } from "api/stock";

function TransfertForm({ closePopup, depotFormAction, showToast }) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProductsList, setSelectedProductsList] = useState([
    {
      Product: "",
      FromD: null,
      ToD: null,
      Quantity: 0,
    },
  ]);

  const [transfertComment, setTransfertComment] = useState("");

  // combox
  const [selectedComboBoxData, setSelectedComboBoxData] = useState("");
  const [query, setQuery] = useState("");
  const [comboBoxData, setComboBoxData] = useState([]);

  useEffect(() => {
    getAllProducts();
  }, []);

  const filteredComboBoxData =
    query === ""
      ? comboBoxData
      : comboBoxData.filter((value) =>
          value.Name.toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const handleSelectedComboBoxData = (e, index) => {
    try {
      const updatedProductsList = [...selectedProductsList];
      updatedProductsList[index].Product = e;
      setSelectedProductsList(updatedProductsList);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFromDepotChange = (e, index, product) => {
    try {
      const updatedProductsList = [...selectedProductsList];

      const fromDObject = comboBoxData
        .find((el) => el.Name === product)
        .Stock.find((el) => el.City === e.target.value);

      updatedProductsList[index].FromD = fromDObject;
      setSelectedProductsList(updatedProductsList);
    } catch (err) {
      console.log(err);
    }
  };

  const handleToDepotChange = (e, index, product) => {
    try {
      const updatedProductsList = [...selectedProductsList];

      const toDObject = comboBoxData
        .find((el) => el.Name === product)
        .Stock.find((el) => el.City === e.target.value);

      updatedProductsList[index].ToD = toDObject;
      setSelectedProductsList(updatedProductsList);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeQuantity = (e, index) => {
    try {
      const updatedProductsList = [...selectedProductsList];
      updatedProductsList[index].Quantity = parseFloat(e.target.value);
      setSelectedProductsList(updatedProductsList);
    } catch (err) {
      console.log(err);
    }
  };

  const getAllProducts = async () => {
    try {
      setIsLoading(true);
      const response = await getAllProductsApi("", "", "");
      setComboBoxData([...response]);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelOp = () => {
    try {
      closePopup("Close");
    } catch (err) {}
  };

  const handleEditeDepot = async () => {
    try {
      setIsLoading(true)

      const hasDuplicateProductName = (productsList) => {
        const names = new Set();
        for (let i = 0; i < productsList.length; i++) {
          if (names.has(productsList[i].Product)) {
            // Product name is duplicated
            return productsList[i].Product;
          } else {
            names.add(productsList[i].Product);
          }
        }
        // No duplicate product names found
        return false;
      };

      const errorList = [];
      const stockObject = [];

      if (hasDuplicateProductName(selectedProductsList)) {
        errorList.push(
          `Le produit ${hasDuplicateProductName(
            selectedProductsList
          )} et dupliquée`
        );
      }

      selectedProductsList.map((el) => {
        if (!el.Product || el.Product === "") {
          errorList.push(`Choisir votre produit`);
        }
        if (!el.FromD || el.FromD === "") {
          errorList.push(`Choisir le depot de d'entrée pour ${el.Product}`);
        }
        if (!el.ToD || el.ToD === "") {
          errorList.push(`Choisir le depot de de sortir pour ${el.Product}`);
        }
        if (!el.Quantity || el.Quantity === 0) {
          errorList.push(`Caissier la quantité de produit ${el.Product}`);
        }
      });

      if (errorList.length !== 0) return showToast("error", errorList[0]);

      selectedProductsList.map((el) => {
        stockObject.push({
          Name: el.Product,
          FromD: el.FromD.City,
          ToD: el.ToD.City,
          Quantity: el.Quantity,
          Type: "TranfertEntry",
          Comment: transfertComment
        });
      });

      await updateStockApi(stockObject, "save");
      showToast("success", "Transferée");
    } catch (err) {
      if (err?.response?.data)
        showToast("error", `${err?.response?.data?.error}`);
    }
    finally {
      setIsLoading(false)
    }
  };

  const handleAddNewProduct = () => {
    try {
      setSelectedProductsList((prevList) => [...prevList, { Product: "" }]);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteProduct = (index) => {
    try {
      const updatedProductsList = [...selectedProductsList];
      updatedProductsList.splice(index, 1);
      setSelectedProductsList(updatedProductsList);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <form>
      {isLoading ? (
        <div className="flex w-full items-center justify-center">
          <Spinner className="h-12 w-12" />
        </div>
      ) : (
        <div style={{ height: "30vh", overflowY: "scroll" }}>
          {selectedProductsList.map((el, index) => (
            <div
              style={{
                border: "1px solid #f8eaea",
                padding: "2%",
                borderRadius: "10px",
              }}
              className="mt-2"
            >
              <div className="grid grid-cols-1 gap-5 md:grid-cols-1">
                <div>
                  <label htmlFor="" className="text-md font-bold text-navy-700">
                    sélectionner un produit ({index + 1})
                  </label>

                  <div>
                    <Combobox
                      value={el.Product}
                      onChange={(e) => handleSelectedComboBoxData(e, index)}
                    >
                      <Combobox.Input
                        onChange={(event) => setQuery(event.target.value)}
                        // displayValue={(value) => value.name}
                        className="relative mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none"
                      />
                      <Combobox.Options className="h-32 w-full overflow-y-scroll bg-white">
                        {filteredComboBoxData.map((value) => (
                          <Combobox.Option
                            key={index}
                            value={value.Name}
                            className="cursor-pointer rounded-xl p-2 hover:bg-gray-100"
                          >
                            {value.Name}
                          </Combobox.Option>
                        ))}
                      </Combobox.Options>
                    </Combobox>
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
                  <div>
                    <label
                      htmlFor=""
                      className="text-md font-bold text-navy-700"
                    >
                      Depuis
                    </label>
                    <select
                      label="select Version"
                      className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
                      defaultValue=""
                      disabled={
                        depotFormAction === "show" ||
                        selectedProductsList[index].Product === ""
                      }
                      value={selectedProductsList[index]?.FromD?.City}
                      onChange={(e) =>
                        handleFromDepotChange(
                          e,
                          index,
                          selectedProductsList[index].Product
                        )
                      }
                    >
                      <option disabled value="">
                        sélectionner un dépôts
                      </option>
                      {comboBoxData
                        .find(
                          (el) =>
                            el.Name ===
                            selectedProductsList[index].Product.trim()
                        )
                        ?.Stock.map((stock, stockIndex) => (
                          <option key={stockIndex} value={stock.City}>
                            {stock.City} [{stock.Disponible}]
                          </option>
                        ))}
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor=""
                      className="text-md font-bold text-navy-700"
                    >
                      À
                    </label>
                    <select
                      label="select Version"
                      className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
                      defaultValue=""
                      disabled={
                        depotFormAction === "show" ||
                        selectedProductsList[index].Product === ""
                      }
                      value={selectedProductsList[index]?.ToD?.City}
                      onChange={(e) =>
                        handleToDepotChange(
                          e,
                          index,
                          selectedProductsList[index].Product
                        )
                      }
                    >
                      <option disabled value="">
                        sélectionner un dépôts
                      </option>
                      {comboBoxData
                        .find(
                          (el) =>
                            el.Name ===
                            selectedProductsList[index].Product.trim()
                        )
                        ?.Stock.map((stock, stockIndex) => (
                          <option key={stockIndex} value={stock.City}>
                            {stock.City} [{stock.Disponible}]
                          </option>
                        ))}
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor=""
                      className="text-md font-bold text-navy-700"
                    >
                      Quantité
                    </label>
                    <input
                      type="number"
                      min="0"
                      id=""
                      placeholder="Quantité"
                      className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
                      value={selectedProductsList[index]?.Quantity}
                      onChange={(e) => handleChangeQuantity(e, index)}
                      disabled={
                        depotFormAction === "show" ||
                        selectedProductsList[index].Product === ""
                      }
                    />
                  </div>
                </div>
              </div>

              {selectedProductsList.length !== 1 && (
                <div className="mt-2 flex items-center justify-center">
                  <FaTrash
                    color="#cf503f"
                    size={30}
                    className="cursor-pointer"
                    onClick={() => handleDeleteProduct(index)}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      <div className="mt-5 flex items-center justify-center">
        <FaPlusCircle
          color="#236571"
          size={50}
          className="cursor-pointer"
          onClick={handleAddNewProduct}
        />
      </div>

      <textarea
        placeholder="Saisissez votre commentaire"
        rows={5}
        className="mt-4 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none"
        value={transfertComment}
        onChange={(e) => setTransfertComment(e.target.value)}
      />

      {isLoading ? (
        <div className="flex w-full items-center justify-center">
          <Spinner className="h-12 w-12" />
        </div>
      ) : (
        <div className="mt-5">
          <Button
            variant="gradient"
            color="#236571"
            onClick={() => handleEditeDepot()}
            className="mr-1"
          >
            <span>Transférer</span>
          </Button>
          <Button
            variant="text"
            color="#cf503f"
            onClick={() => handleCancelOp()}
            className="mr-1"
          >
            <span>Annuler</span>
          </Button>
        </div>
      )}
    </form>
  );
}

export default TransfertForm;
