import { Button } from "@material-tailwind/react";
import { updateOneEssayStatusReportsApi } from "api/essay";
import React, { useState } from "react";

function EssayCommentForm({ reportDetailsData, showToast }) {
  const [essayFormData, setEssayFormData] = useState({
    FinalComment: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEssayFormData({ ...essayFormData, [name]: value });
  };

  const handleValidateEssaiReport = async () => {
    try {
        await updateOneEssayStatusReportsApi(reportDetailsData._id, "V", essayFormData.FinalComment)
        showToast("success", "Modifiée")

        // console.log(reportDetailsData._id)
        // console.log(essayFormData.FinalComment)
    }
    catch (err) {
        console.log(err);
    }
  }

  return (
    <form
      style={{
        overflow: "scroll",
        height: "80vh",
      }}
    >
      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-1">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Commentaire
          </label>
          <textarea
            type="text"
            id=""
            placeholder="Nom Client"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            rows="8"
            value={essayFormData?.FinalComment}
            name="FinalComment"
            onChange={handleInputChange}
          />
        </div>
      </div>

      <Button
        variant="gradient"
        color="#236571"
        onClick={handleValidateEssaiReport}
        className="mt-5"
      >
        <span>Terminer</span>
      </Button>
    </form>
  );
}

export default EssayCommentForm;
